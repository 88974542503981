/**
 * Parses the provided query string portion of a url into an object.
 * @param {String} search The query string portion of the url.
 * @returns Object
 */
export function parseQueryStrings(search) {
  const parsedQueryStrings = {};

  if (search) {
    const urlSearchParam = new URLSearchParams(search);

    for (const [key, value] of urlSearchParam.entries()) {
      parsedQueryStrings[key] = value;
    }
  }

  return parsedQueryStrings;
}

/**
 * Converts the provided query string values into a valid query string.
 * @param {Object} queryStringValues The query string values to convert to a valid query string.
 */
export function stringifyQueryStrings(queryStringValues) {
  let queryStrings = '';

  if (queryStringValues) {
    for (const [key, value] of Object.entries(queryStringValues)) {
      if (value) {
        queryStrings += `${key}=${value}&`;
      }
    }

    // Remove the trailing &.
    queryStrings = queryStrings.slice(0, -1);
  }

  return queryStrings;
}
