import EventEmitter from 'eventemitter3';

/**
 * Represents an event emitter proxy.
 */
class EventsProxy {
  /**
   * Initializes a new instance of the EventsProxy class.
   */
  constructor() {
    if (!EventsProxy.instance) {
      this.EE = new EventEmitter();
      EventsProxy.instance = this;
    }

    return EventsProxy.instance;
  }

  /**
   * Emits the event name to all listeners.
   * @param {String} eventName The event name.
   * @param {any} args The arguments to pass to the callback.
   */
  emit(eventName, args) {
    this.EE.emit(eventName, args);
  }

  /**
   * Adds a listener to the collectiong of listeners.
   * @param {String} eventName The event name.
   * @param {Function} callback The callback to execute for the event.
   * @param {Object} context (optional) The context to execute the callback in.
   */
  on(eventName, callback, context) {
    this.EE.on(eventName, callback, context);
  }

  /**
   * Removes a listener from the collection of listeners.
   * @param {String} eventName The event name.
   * @param {Function} callback The callback to execute for the event.
   * @param {Object} context (optional) The context to execute the callback in.
   */
  removeListener(eventName, callback, context) {
    this.EE.removeListener(eventName, callback, context);
  }
}

/**
 * Represents an event emitter for the publish/subscribe pattern.
 */
const Events = new EventsProxy();
// Lock object to prevent modification (true singleton).
Object.freeze(Events);

export default Events;
