import './init';
import './components/loader/waveLoader';
import './components/awardsCalculator/awardsCalculator';
import './components/comment/comment';
import './components/overlay/overlay';
import './components/modal/modal';
import './components/drawer/drawer';
import './components/carousel/carousel';
import './components/carousel/carouselSlide';
import './components/compactCarousel/compactCarousel';
import './components/compactCarousel/compactCarouselSlide';
import './components/accordion/accordion';
import './components/accordion/accordionTab';
import './components/pagination/pagination';
import './components/autocomplete/autocomplete';
import './components/facilityLookup/facilityLookup';
import './components/controls/autocompleteChecklist/autocompleteChecklist';
import './components/controls/checkbox/checkbox';
import './components/controls/dropdown/dropdown';
import './components/controls/filteredDropdown/filteredDropdown';
import './components/map/map';
import './components/step/step';
import headerPartial from './views/partials/header';
import subnavPartial from './views/partials/subnav';
import masterView from './views/master';
import aboutUsView from './views/aboutUs';
import articlesView from './views/articles';
import atlanticCaresView from './views/atlanticCares';
import advisoriesView from './views/advisories';
import contactUsView from './views/contactUs';
import nominateEmployeeView from './views/nominateEmployee';
import locationsView from './views/locations';
import cardCarouselPartial from './views/partials/cardCarousel';
import realEstateView from './views/realEstate';

const loadViewScripts = () => {
  headerPartial();
  subnavPartial();
  masterView();
  aboutUsView();
  articlesView();
  atlanticCaresView();
  advisoriesView();
  contactUsView();
  nominateEmployeeView();
  locationsView();
  cardCarouselPartial();
  realEstateView();
};

// We need to handle the scenario of users navigating
// back to our site via the browser back button. Browsers
// will reload our page from cache, which causes scripts
// to not run again if they rely on page load. pageshow
// allows us to fix this issue.
window.addEventListener('pageshow', loadViewScripts);
