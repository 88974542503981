import { KeyboardKeys } from '../../../enums';
import bemify from '../../../utils/bemUtils';
import Component from '../../component/component';

class Dropdown extends Component {
  static formAssociated = true;

  #templateRef = null;
  #dropdownRef = null;
  #dropdownLabelRef = null;
  #dropdownItemsRef = null;
  #loadedItems = [];

  get defaultValue() {
    return this.getAttribute('default-value');
  }

  set defaultValue(value) {
    this.setAttribute('default-value', value);
    this.value = value;
  }

  get items() {
    return JSON.parse(this.getAttribute('items') ?? '[]');
  }

  set items(value) {
    this.setAttribute('items', value);
  }

  get label() {
    return this.#dropdownLabelRef?.textContent ?? '';
  }

  set label(value) {
    if (this.#dropdownLabelRef) {
      if (value) {
        this.#dropdownLabelRef.textContent = value;
        this.#dropdownLabelRef.classList.remove('dropdown__label--placeholder');
      } else {
        this.#dropdownLabelRef.textContent = this.placeholder;
        this.#dropdownLabelRef.classList.add('dropdown__label--placeholder');
      }
    }
  }

  get placeholder() {
    return this.getAttribute('placeholder');
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  get open() {
    return this.hasAttribute('open');
  }

  set open(value) {
    if (value) {
      this.setAttribute('open', '');
    } else {
      this.removeAttribute('open');
    }
  }

  get value() {
    return this.getAttribute('value') ?? '';
  }

  set value(value) {
    this.setAttribute('value', value);
    this.label = this.#getItem(value)?.label;
  }

  constructor() {
    super();
    this.internals = this.attachInternals();
  }

  initTemplate() {
    const [block, element] = bemify('dropdown');

    this.#templateRef = document.createElement('template');
    this.#templateRef.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <div class="${block()}">
        <button
          class="${element('dropdown')}"
          type="button"
          aria-expanded="${this.open}">
          <span class="${element('label', !this.label && this.placeholder ? 'placeholder' : '')}">${
      this.label || this.placeholder
    }</span>
          <span class="material-symbols-outlined ${element('icon')}">keyboard_arrow_down</span>
        </button>
        <ul class="${element('items')}"></ul>
      </div>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.#templateRef.content.cloneNode(true));
  }

  onMounted() {
    // Close on click-away.
    document.addEventListener('click', e => {
      const target = e.composedPath()[0];
      if (target !== this && !this.shadowRoot.contains(target)) {
        this.#onToggleDropdown(false);
      }
    });

    // Close on 'ESC' key.
    document.addEventListener('keydown', e => {
      if (e.key === KeyboardKeys.esc) {
        this.#onToggleDropdown(false);
      }
    });

    this.#dropdownRef = this.shadowRoot.querySelector('.dropdown__dropdown');

    if (this.#dropdownRef) {
      this.#dropdownRef.addEventListener('click', () => this.#onToggleDropdown());
    }

    this.#dropdownLabelRef = this.shadowRoot.querySelector('.dropdown__label');
    this.#dropdownItemsRef = this.shadowRoot.querySelector('.dropdown__items');
  }

  /**
   * Called once to return the attributes to observe.
   */
  static get observedAttributes() {
    return ['items', 'open', 'defaultValue'];
  }

  render() {
    if (this.open) {
      this.#dropdownRef?.classList.add('dropdown__dropdown--opened');
      this.#dropdownItemsRef?.classList.add('dropdown__items--visible');
    } else {
      this.#dropdownRef?.classList.remove('dropdown__dropdown--opened');
      this.#dropdownItemsRef?.classList.remove('dropdown__items--visible');
    }

    this.#onItemsChange();
    return;
  }

  reset() {
    this.label = '';

    const dropdownItems = this.shadowRoot.querySelectorAll('.dropdown__item');

    for (const dropdownItem of dropdownItems) {
      dropdownItem.classList.remove('dropdown__item--active');
    }
  }

  #onItemsChange() {
    if (this.#dropdownItemsRef && JSON.stringify(this.#loadedItems) != JSON.stringify(this.items)) {
      const newItems = this.items;

      this.reset();
      this.#dropdownItemsRef.innerHTML = '';
      this.#initItems();

      this.#loadedItems = newItems;

      if (this.defaultValue) {
        this.value = this.defaultValue;
      }
    }
  }

  #adjustItemsPosition() {
    const dropdownBounds = this.#dropdownRef.getBoundingClientRect();
    const dropdownItemsBounds = this.#dropdownItemsRef.getBoundingClientRect();

    if (dropdownBounds.bottom + dropdownItemsBounds.height >= document.documentElement.clientHeight) {
      this.#dropdownItemsRef.style.bottom = '105%';
      this.#dropdownItemsRef.style.top = 'unset';
    } else {
      this.#dropdownItemsRef.style.top = '105%';
      this.#dropdownItemsRef.style.bottom = 'unset';
    }
  }

  /**
   * Retrieves the first item that matches the given value.
   * @param {number} value The drop-down value to search by.
   * @returns The first item that matches the given value.
   */
  #getItem(value) {
    let foundItem = null;

    for (let index = 0; index < this.items?.length; ++index) {
      const item = this.items[index];
      if (item.items) {
        foundItem = item.items.find(i => i.value === value);
      } else {
        foundItem = item.value === value ? item : null;
      }
      if (foundItem) {
        break;
      }
    }
    return foundItem;
  }

  #initItems() {
    for (let index = 0; index < this.items?.length; ++index) {
      const item = this.items[index];

      if (item.items) {
        const groupLabel = document.createElement('label');
        groupLabel.textContent = item.label;
        groupLabel.classList.add('dropdown__item-group');

        const groupListItem = document.createElement('li');
        groupListItem.appendChild(groupLabel);

        this.#dropdownItemsRef.appendChild(groupListItem);

        for (const element of item.items) {
          const groupItem = element;
          const itemLabel = document.createElement('span');
          itemLabel.textContent = groupItem.label;

          const dropdownItem = document.createElement('button');
          dropdownItem.classList.add('dropdown__item');
          dropdownItem.classList.add('dropdown__item--group-item');
          dropdownItem.setAttribute('value', groupItem.value);
          dropdownItem.addEventListener('click', () =>
            this.#onItemClick(dropdownItem, groupItem.value, groupItem.additionalData)
          );
          dropdownItem.appendChild(itemLabel);

          const listItem = document.createElement('li');
          listItem.appendChild(dropdownItem);

          this.#dropdownItemsRef.appendChild(listItem);
        }
      } else {
        const itemLabel = document.createElement('span');
        itemLabel.textContent = item.label;

        const dropdownItem = document.createElement('button');
        dropdownItem.classList.add('dropdown__item');
        dropdownItem.setAttribute('value', item.value);
        dropdownItem.setAttribute('data-additional', item.additionalData);
        dropdownItem.addEventListener('click', () => this.#onItemClick(dropdownItem, item.value, item.additionalData));
        dropdownItem.appendChild(itemLabel);

        const li = document.createElement('li');
        li.appendChild(dropdownItem);

        this.#dropdownItemsRef.appendChild(li);
      }
      this.label = this.#getItem(this.value)?.label;
    }
  }

  #onItemClick(item, value, additionalData = '') {
    this.reset();

    item.classList.add('dropdown__item--active');

    this.value = value;

    this.#onToggleDropdown(false);

    const event = new CustomEvent('on-change', {
      detail: {
        value,
        additionalData
      }
    });
    this.internals.setFormValue(value);

    this.dispatchEvent(event);
  }

  #onToggleDropdown(isOpening = !this.open) {
    if (isOpening === this.open) {
      return;
    }

    let event = null;
    if (isOpening) {
      event = new CustomEvent('on-before-open', { detail: this.#dropdownItemsRef });
    } else {
      event = new CustomEvent('on-before-close', { detail: this.#dropdownItemsRef });
    }

    this.dispatchEvent(event);
    this.open = isOpening;

    if (this.open) {
      this.#adjustItemsPosition();
    }
  }
}

customElements.define('sk-dropdown', Dropdown);
export default Dropdown;
