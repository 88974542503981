/*global google*/

import Events from '../../events';
import bemify from '../../utils/bemUtils';
import Component from '../component/component';
import { EventMessages, MapConstants } from '../../constants';

/**
 * Represents a map component utilizing Google Maps internally.
 */
class Map extends Component {
  #map = null;
  #templateRef = null;

  /**
   * Gets the underlying map object.
   */
  get map() {
    return this.#map;
  }

  set zoom(value) {
    this.#map?.setZoom(value);
  }

  initTemplate() {
    const [block] = bemify('map');

    this.#templateRef = document.createElement('template');
    this.#templateRef.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <div
        id="map"
        class="${block()}"></div>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.#templateRef.content.cloneNode(true));
  }

  onMounted() {
    Events.on(EventMessages.mapsInit, () => this.#initMaps());
  }

  /**
   * Pans the map to a position.
   * @param {Object} position The position to pan to.
   */
  panTo(position) {
    this.#map?.panTo(position);
  }

  #getMapOptions() {
    return {
      zoom: MapConstants.defaultZoom,
      minZoom: MapConstants.minZoom,
      maxZoom: MapConstants.maxZoom,
      draggable: true,
      scrollwheel: false,
      mapId: process.env.MAP_ID,
      mapTypeControl: false,
      fullscreenControl: false,
      center: MapConstants.usCenter,
      mapTypeControlOptions: {
        position: google.maps.ControlPosition.BOTTOM_CENTER,
        mapTypeIds: [google.maps.MapTypeId.ROADMAP, 'map_style']
      },
      zoomControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      },
      panControlOptions: {
        position: google.maps.ControlPosition.LEFT_CENTER
      },
      streetViewControl: false
    };
  }

  async #initMaps() {
    const { Map, InfoWindow } = await google.maps.importLibrary('maps');
    const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary('marker');

    // Store the google map objects to easy access.
    window.aa = {
      Map: Map,
      GInfoWindow: InfoWindow,
      PinElement: PinElement,
      AdvancedMarkerElement: AdvancedMarkerElement
    };

    const mapOptions = this.#getMapOptions();
    const map = this.shadowRoot.getElementById('map');

    this.#map = new Map(map, mapOptions);
  }
}

customElements.define('sk-map', Map);

export default Map;
