import { KeyboardKeys } from '../../../enums';
import bemify from '../../../utils/bemUtils';
import Component from '../../component/component';

class FilteredDropdown extends Component {
    #templateRef = null;
    #dropdownRef = null;
    #dropdownLabelRef = null;
    #dropdownItemsRef = null;
    #label = '';
    #appliedFilters = [];

    get items() {
        return this.getAttribute('items');
    }

    set items(value) {
        this.setAttribute('items', value);
    }

    get placeholder() {
        return this.getAttribute('placeholder');
    }

    set placeholder(value) {
        this.setAttribute('placeholder', value);
    }

    get chipText() {
        return this.getAttribute('chiptext');
    }

    set chipText(value) {
        this.setAttribute('chiptext', value);
    }
    get selectedOnTop() {
        return this.hasAttribute('selectedontop');
    }

    set selectedOnTop(value) {
        if (value) {
            this.setAttribute('selectedontop', '');
        } else {
            this.removeAttribute('selectedontop');
        }
    }

    get open() {
        return this.hasAttribute('open');
    }

    set open(value) {
        if (value) {
            this.setAttribute('open', '');
        } else {
            this.removeAttribute('open');
        }
    }

    initTemplate() {
        const [block, element] = bemify('filtered-dropdown');

        this.#templateRef = document.createElement('template');
        this.#templateRef.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <div class="${block()}">
        <button
          class="${element('dropdown')}"
          type="button"
          aria-expanded="${this.open}">
          <span class="${element('label', !this.#label && this.placeholder ? 'placeholder' : '')}">${this.#label || this.placeholder}</span>
          <span class="material-symbols-outlined ${element('icon')}">keyboard_arrow_down</span>
        </button>
        <ul class="${element('items')}"></ul>
      </div>
    `;

        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(this.#templateRef.content.cloneNode(true));
    }

    onMounted() {
        // Close on click-away.
        document.addEventListener('click', e => {
            const target = e.composedPath()[0];
            if (target !== this && !this.shadowRoot.contains(target)) {
                this.#onToggleDropdown(false);
            }
        });

        // Close on 'ESC' key.
        document.addEventListener('keydown', e => {
            if (e.key === KeyboardKeys.esc) {
                this.#onToggleDropdown(false);
            }
        });

        this.#dropdownRef = this.shadowRoot.querySelector('.filtered-dropdown__dropdown');

        if (this.#dropdownRef) {
            this.#dropdownRef.addEventListener('click', () => this.#onToggleDropdown());
        }

        this.#dropdownLabelRef = this.shadowRoot.querySelector('.filtered-dropdown__label');

        if (this.#dropdownLabelRef) {
            this.#dropdownLabelRef.addEventListener('click', e => {
                e.stopPropagation();

                const event = new CustomEvent('on-clear');

                this.dispatchEvent(event);
            });
        }

        this.#dropdownItemsRef = this.shadowRoot.querySelector('.filtered-dropdown__items');

        if (this.#dropdownItemsRef) {
            this.#initItems();
        }
    }

    /**
     * Called once to return the attributes to observe.
     */
    static get observedAttributes() {
        return ['items', 'open'];
    }

    render() {
        if (this.open) {
            this.#dropdownRef?.classList.add('filtered-dropdown__dropdown--opened');
            this.#dropdownItemsRef?.classList.add('filtered-dropdown__items--visible');
        } else {
            this.#dropdownRef?.classList.remove('filtered-dropdown__dropdown--opened');
            this.#dropdownItemsRef?.classList.remove('filtered-dropdown__items--visible');
        }
        return;
    }

    #adjustItemsPosition() {
        const dropdownBounds = this.#dropdownRef.getBoundingClientRect();
        const dropdownItemsBounds = this.#dropdownItemsRef.getBoundingClientRect();

        if (dropdownBounds.bottom + dropdownItemsBounds.height >= document.documentElement.clientHeight) {
            this.#dropdownItemsRef.style.bottom = '105%';
            this.#dropdownItemsRef.style.top = 'unset';
        } else {
            this.#dropdownItemsRef.style.top = '105%';
            this.#dropdownItemsRef.style.bottom = 'unset';
        }
    }

    #initItems() {
        let items = JSON.parse(this.items);

        if (this.selectedOnTop) {
            items = items.filter(item => item.isApplied).concat(items.filter(item => !item.isApplied));
        }

        for (let index = 0; index < items?.length; ++index) {
            const item = items[index];
            const itemLabel = document.createElement('span');
            itemLabel.textContent = item.label;

            const filter = document.createElement('sk-checkbox');
            filter.label = item.label;
            filter.defaultChecked = item.isApplied;
            filter.setAttribute('fullwidth', '');
            filter.addEventListener('on-change', ({ detail: checked }) => this.#onItemChange(filter, checked, item.value));

            const hr = document.createElement('hr');
            hr.classList.add('filtered-dropdown__delimiter');

            const li = document.createElement('li');
            li.classList.add('filtered-dropdown__item');
            li.appendChild(filter);
            li.appendChild(hr);

            if (item.isApplied) {
                this.#appliedFilters.push(item.value);
            }

            this.#updateLabel();

            this.#dropdownItemsRef.appendChild(li);
        }
    }

    #onItemChange(item, checked, value) {
        if (checked && !this.#appliedFilters.includes(value)) {
            this.#appliedFilters.push(value);
            item.parentElement.classList.add('filtered-dropdown__item--active');
        } else {
            this.#appliedFilters.splice(this.#appliedFilters.indexOf(value), 1);
            item.parentElement.classList.remove('filtered-dropdown__item--active');
        }

        this.#updateLabel();

        const event = new CustomEvent('on-change', { detail: { checked: checked, value: value } });

        this.dispatchEvent(event);
    }

    #onToggleDropdown(isOpening = !this.open) {
        if (isOpening === this.open) {
            return;
        }

        let event = null;
        if (isOpening) {
            event = new CustomEvent('on-before-open', { detail: this.#dropdownItemsRef });
        } else {
            event = new CustomEvent('on-before-close', { detail: this.#dropdownItemsRef });
        }

        this.dispatchEvent(event);
        this.open = isOpening;

        if (this.open) {
            this.#adjustItemsPosition();
        }
    }

    #updateLabel() {
        if (this.#appliedFilters.length > 0) {
            if (this.chipText) {
                this.#dropdownLabelRef.innerHTML = `${this.chipText} (${this.#appliedFilters.length})<span class="material-symbols-outlined">close</span>`;
            } else {
                this.#dropdownLabelRef.innerHTML = `${this.#appliedFilters.length}<span class="material-symbols-outlined">close</span>`;
            }

            this.#dropdownLabelRef.classList.remove('filtered-dropdown__label--placeholder');
        } else {
            this.#dropdownLabelRef.textContent = this.placeholder;
            this.#dropdownLabelRef.classList.add('filtered-dropdown__label--placeholder');
        }
    }
}

customElements.define('sk-filtered-dropdown', FilteredDropdown);

export default FilteredDropdown;
