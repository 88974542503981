const atlanticCaresView = () => {
  if (document.querySelector('.atlantic-cares')) {
    const pagination = document.getElementById('pagination');

    if (pagination) {
      pagination.addEventListener('on-change', ({ detail: page }) => {
        const queryStrings = new URLSearchParams(location.search);
        let configTake = queryStrings.get('take');

        if (!configTake) {
          configTake = parseInt(process.env.APP_ATLANTIC_CARES_ARTICLES_TAKE);
        } else {
          configTake = parseInt(configTake);
        }

        let gatedPage = page;

        if (gatedPage > pagination.totalPages) {
          gatedPage = gatedPage - 1;
        } else if (gatedPage <= 0) {
          gatedPage = 1;
        }

        const skip = (gatedPage - 1) * configTake;

        queryStrings.set('page', gatedPage);
        queryStrings.set('skip', skip);
        queryStrings.set('take', configTake);

        location.search = queryStrings.toString();
      });
    }
  }
};

export default atlanticCaresView;
