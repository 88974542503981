import bemify from '../../utils/bemUtils';
import Component from '../component/component';

class CompactCarouselSlide extends Component {
    initTemplate() {
        const [_, element] = bemify('compact-carousel');
        const template = document.createElement('template');
        template.innerHTML = `
            <style>
                @import url('${process.env.APP_CSS_PATH}');
            </style>
            <li class="${element('slide')}">
                <div class="${element('slide-content')}">
                    <slot />
                </div>
            </li>
        `;

        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    onMounted() {
        this.style.minWidth = '100%';
    }
}

customElements.define('sk-compact-carousel-slide', CompactCarouselSlide);

export default CompactCarouselSlide;
