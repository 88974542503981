import bemify from '../../utils/bemUtils';
import Component from '../component/component';

class Accordion extends Component {
    initTemplate() {
        const [block, _] = bemify('accordion');
        const template = document.createElement('template');
        template.innerHTML = `
            <style>
            @import url('${process.env.APP_CSS_PATH}');
            </style>
            <section class="${block()}">
                <slot />
            </section>
        `;

        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }
}

customElements.define('sk-accordion', Accordion);
export default Accordion;
