/**
 * Represents the supported drawer anchor positions.
 */
class DrawerAnchor {
  /**
   * Indicates that the drawer should be anchored at the bottom of the screen.
   */
  static bottom = 'bottom';

  /**
   * Indicates that the drawer should be anchored to the left of the screen.
   */
  static left = 'left';

  /**
   * Indicates that the drawer should be anchored to the right of the screen.
   */
  static right = 'right';

  /**
   * Indicates that the drawer should be anchored at the top of the screen.
   */
  static top = 'top';
}

export default DrawerAnchor;
