/**
 * Represents a collection of strongly typed regular expressions.
 */
class RegEx {
  /**
   * Gets a regular expression used to validate emails.
   */
  static email =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  /**
   * Gets a regular expression used to validate United States phone number.
   */
  static usPhone = /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[\0-9]{3}[\s-]?[0-9]{4}$/;

  /**
   * Gets a regular expression used to validate United States zip codes.
   */
  static usZip = /(^\d{5}$)|(^\d{5}-\d{4}$)/;

  /**
   * Gets a regular expression used to validate Canadian zip codes.
   */
  static caZip = /^[ABCEGHJKLMNPRSTVXY]{1}\d{1}[A-Z]{1} *\d{1}[A-Z]{1}\d{1}$/;
}

// Lock object to prevent modification (true static).
Object.freeze(RegEx);

export default RegEx;
