/*global grecaptcha*/

import Rest from '../rest';
import RegEx from '../regex';
import Events from '../events';
import { HttpStatusCode } from '../enums';

const nominateEmployeeView = () => {
  if (document.querySelector('.nominate-employee')) {
    window.onbeforeunload = () => {
      window.scrollTo(0, 0);
    };

    window.scrollTo(0, 0);

    const termsCond = document.getElementById('terms-cond');
    let agreedTermsConds = false;

    if (termsCond) {
      termsCond.addEventListener('on-change', ({ detail: checked }) => {
        if (checked) {
          agreedTermsConds = true;
          clearFieldErrors(termsCond);
        } else {
          agreedTermsConds = false;
        }
      });
    }

    const submit = document.querySelector('.form__submit');

    if (submit) {
      submit.addEventListener('click', () => {
        if (validateForm()) {
          challengeUser();
        }
      });
    }

    const successModal = document.getElementById('success-modal');
    const successModalClose = document.getElementById('success-modal-close');

    if (successModal && successModalClose) {
      successModalClose.addEventListener('click', () => closeSuccessModal());
      successModal.onClose = () => closeSuccessModal();
    }

    const successModalDone = document.getElementById('success-modal-done');

    if (successModal && successModalDone) {
      successModalDone.addEventListener('click', () => closeSuccessModal());
      successModal.onClose = () => closeSuccessModal();
    }

    const errorModal = document.getElementById('error-modal');
    const errorModalClose = document.getElementById('error-modal-close');

    if (errorModal && errorModalClose) {
      errorModalClose.addEventListener('click', () => closeErrorModal());
      errorModalClose.onClose = () => closeSuccessModal();
    }

    const errorModalDone = document.getElementById('error-modal-done');

    if (errorModal && errorModalDone) {
      errorModalDone.addEventListener('click', () => closeErrorModal());
      errorModalClose.onClose = () => closeSuccessModal();
    }

    const form = document.querySelector('.form');
    const email = document.getElementById('email');
    const feedback = document.getElementById('feedback');
    const lastName = document.getElementById('last-name');
    const locations = document.getElementById('locations');
    const firstName = document.getElementById('first-name');
    const phoneNumber = document.getElementById('phone-number');
    const submitLoader = document.getElementById('submit-loader');
    const employeeFirstName = document.getElementById('employee-first-name');
    const employeeLastName = document.getElementById('employee-last-name');
    const employeeDescription = document.getElementById('employee-description');
    const requiredFields = [feedback, firstName, lastName, phoneNumber, email];

    locations.addEventListener('on-change', () => clearFieldErrors(locations));

    requiredFields.forEach(field => {
      if (field)
        field.addEventListener('input', () => {
          clearFieldErrors(field);
        });
    });

    Events.on('recaptcha-validate', token => {
      const submitForm = async () => {
        try {
          submitLoader.show = true;

          const formData = {
            senderEmail: email?.value,
            feedback: feedback?.value,
            locationId: locations?.value,
            agreeToTerms: agreedTermsConds,
            senderLastName: lastName?.value,
            senderFirstName: firstName?.value,
            senderPhoneNumber: phoneNumber?.value,
            employeeLastName: employeeLastName.value,
            employeeFirstName: employeeFirstName.value,
            employeeDescription: employeeDescription.value,
            recaptchaResponse: token
          };
          const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          };

          const response = await Rest.post(`${process.env.API_NOMINATE_EMPLOYEE}/Store`, formData, config);

          if (response?.status === HttpStatusCode.created) {
            successModal.show = true;
          } else {
            errorModal.show = true;
          }
        } catch (error) {
          console.error(error);
          errorModal.show = true;
        } finally {
          submitLoader.show = false;
        }
      };
      submitForm();
    });

    const challengeUser = () => {
      grecaptcha.execute();
    };

    const clearFieldErrors = field => {
      const requiredMessage = field.parentNode.querySelector('.form__required-message--visible');
      const invalidMessage = field.parentNode.querySelector('.form__invalid-message--visible');

      if (requiredMessage) requiredMessage.classList.remove('form__required-message--visible');
      if (invalidMessage) invalidMessage.classList.remove('form__invalid-message--visible');
    };

    const clearErrors = () => {
      const requiredMessages = document.querySelectorAll('.form__required-message');

      for (let index = 0; index < requiredMessages?.length; ++index) {
        const requiredMessage = requiredMessages[index];

        if (requiredMessage) {
          requiredMessage.classList.remove('form__required-message--visible');
        }
      }

      const invalidMessages = document.querySelectorAll('.form__invalid-message');

      for (let index = 0; index < invalidMessages?.length; ++index) {
        const invalidMessage = invalidMessages[index];

        if (invalidMessage) {
          invalidMessage.classList.remove('form__invalid-message--visible');
        }
      }
    };

    const closeErrorModal = () => {
      if (errorModal) {
        errorModal.show = false;
        resetForm();
      }
    };

    const closeSuccessModal = () => {
      if (successModal) {
        successModal.show = false;
        location.reload();
      }
    };

    const resetForm = () => {
      if (form) {
        form.reset();
        termsCond.reset();
        agreedTermsConds = false;
        locations.reset();
        locations.value = '';
      }
    };

    const showErrorMessage = (field, group, errorClass, errorClassVisible) => {
      const errorMessage = group?.querySelector(`.${errorClass}`);

      if (errorMessage) {
        field.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        });
        field.focus();
        errorMessage.classList.add(errorClassVisible);
      }
    };

    const validateForm = () => {
      clearErrors();

      let hasErrors = false;

      if (!agreedTermsConds) {
        hasErrors = true;
        showErrorMessage(
          termsCond,
          termsCond.parentElement,
          'form__required-message',
          'form__required-message--visible'
        );
      }

      if (!email?.value) {
        hasErrors = true;
        showErrorMessage(email, email.parentElement, 'form__required-message', 'form__required-message--visible');
      } else if (email?.value && !RegEx.email.test(email.value)) {
        hasErrors = true;
        showErrorMessage(email, email.parentElement, 'form__invalid-message', 'form__invalid-message--visible');
      }

      if (phoneNumber?.value) {
        phoneNumber.value = phoneNumber.value.trim();
      }

      if (!phoneNumber?.value) {
        hasErrors = true;
        showErrorMessage(
          phoneNumber,
          phoneNumber.parentElement,
          'form__required-message',
          'form__required-message--visible'
        );
      } else if (
        phoneNumber.value &&
        ((phoneNumber.value?.match(/[^ 0-9()+-]/g)?.length ?? 0) > 0 ||
          (phoneNumber.value?.match(/\d/g)?.length ?? 0) < 10)
      ) {
        hasErrors = true;
        showErrorMessage(
          phoneNumber,
          phoneNumber.parentElement,
          'form__invalid-message',
          'form__invalid-message--visible'
        );
      }

      if (!lastName?.value) {
        hasErrors = true;
        showErrorMessage(lastName, lastName.parentElement, 'form__required-message', 'form__required-message--visible');
      }

      if (!firstName?.value) {
        hasErrors = true;
        showErrorMessage(
          firstName,
          firstName.parentElement,
          'form__required-message',
          'form__required-message--visible'
        );
      }

      if (!feedback?.value) {
        hasErrors = true;
        showErrorMessage(feedback, feedback.parentElement, 'form__required-message', 'form__required-message--visible');
      }

      if (!locations?.value) {
        hasErrors = true;
        showErrorMessage(
          locations,
          locations.parentElement,
          'form__required-message',
          'form__required-message--visible'
        );
      }

      return !hasErrors;
    };
  }
};

export default nominateEmployeeView;
