import bemify from '../../../utils/bemUtils';
import CheckboxSize from './checkboxSize';
import Component from '../../component/component';

class Checkbox extends Component {
  #templateRef = null;
  #checkboxRef = null;
  #checkboxInputRef = null;

  get defaultChecked() {
    return this.hasAttribute('checked');
  }

  set defaultChecked(value) {
    if (value) {
      this.setAttribute('checked', '');
    } else {
      this.removeAttribute('checked');
    }
  }

  get disabled() {
    return this.hasAttribute('disabled');
  }

  set disabled(value) {
    if (value) {
      this.setAttribute('disabled', '');
    } else {
      this.removeAttribute('disabled');
    }
  }

  get label() {
    return this.getAttribute('label');
  }

  set label(value) {
    this.setAttribute('label', value);
  }

  get size() {
    let sizeAttr = this.getAttribute('size');

    if (!sizeAttr) {
      sizeAttr = CheckboxSize.small;
    }

    return sizeAttr;
  }

  set size(value) {
    this.setAttribute('size', value);
  }

  get tabIndex() {
    return this.getAttribute('tabindex') ?? '';
  }

  set tabIndex(value) {
    this.setAttribute('tabindex', value);
  }

  get fullWidth() {
    return this.hasAttribute('fullwidth');
  }

  set fullWidth(value) {
    if (value) {
      this.setAttribute('fullwidth', '');
    } else {
      this.removeAttribute('fullwidth');
    }
  }

  initTemplate() {
    const [block, element] = bemify('checkbox');

    this.#templateRef = document.createElement('template');
    this.#templateRef.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <label class="${block(this.size)} ${this.fullWidth ? element('full-width') : ''}">
        <input
          class="${element('checkbox', this.size)}"
          type="checkbox"
          ${this.tabIndex ? `tabIndex=${this.tabIndex}` : ''}
          />
        ${this.label ? this.label : ''}
      </label>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.#templateRef.content.cloneNode(true));
  }

  onMounted() {
    this.#checkboxRef = this.shadowRoot.querySelector('.checkbox');
    this.#checkboxInputRef = this.shadowRoot.querySelector('.checkbox__checkbox');

    if (this.#checkboxInputRef) {
      this.#checkboxInputRef.addEventListener('change', e => this.#onChange(e.target.checked));

      if (this.defaultChecked) {
        this.#checkboxInputRef.setAttribute('checked', '');
      }
    }
  }

  /**
   * Called once to return the attributes to observe.
   */
  static get observedAttributes() {
    return ['defaultChecked', 'disabled', 'label', 'size', 'fullwidth'];
  }

  render() {
    if (this.#checkboxRef && this.#checkboxInputRef) {
      if (this.disabled) {
        this.#checkboxRef.classList.add('disabled');
        this.#checkboxInputRef.setAttribute('disabled', '');
      } else {
        this.#checkboxRef.classList.remove('disabled');
        this.#checkboxInputRef.removeAttribute('disabled');
      }

      if (this.fullWidth) {
        this.#checkboxRef.classList.add('full-width');
      } else {
        this.#checkboxRef.classList.remove('full-width');
      }
    }
    return;
  }

  reset() {
    this.#checkboxInputRef.checked = false;
  }

  #onChange(checked) {
    const event = new CustomEvent('on-change', { detail: checked });

    this.dispatchEvent(event);
  }
}

customElements.define('sk-checkbox', Checkbox);

export default Checkbox;
