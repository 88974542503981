import bemify from '../../utils/bemUtils';
import Component from '../component/component';
import { setDocumentOverflowToHidden, setDocumentOverflowToInitial } from '../../utils/domUtils';

/**
 * Represents an overlay that narrows the user's focus.
 */
class Overlay extends Component {
  #overlayRef = null;
  #templateRef = null;

  initTemplate() {
    if (this.show) {
      setDocumentOverflowToHidden();
    }

    const [block] = bemify('overlay');
    this.#templateRef = document.createElement('template');
    this.#templateRef.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <div class="${block([this.show ? 'visible' : '', !this.showBackground ? 'no-background' : ''])}">
        <slot></slot>
      </div>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.#templateRef.content.cloneNode(true));
  }

  /**
   * Returns a value that determines whether the component is visible.
   */
  get show() {
    return this.hasAttribute('show');
  }

  /**
   * Sets a value that determines whether the component is visible.
   */
  set show(value) {
    if (value) {
      this.setAttribute('show', '');
      setDocumentOverflowToHidden();
    } else {
      this.removeAttribute('show');
      // Delay showing scrollbars, so that the overlay
      // has time to fade out without content shifting.
      const quarterOfASecond = 250;
      const timeout = setTimeout(() => {
        clearTimeout(timeout);
        setDocumentOverflowToInitial();
      }, quarterOfASecond);
    }
  }

  /**
   * Gets a value that determines whether to show the background overlay.
   */
  get showBackground() {
    return !this.hasAttribute('show-background');
  }

  /**
   * Sets a value that determines whether to show the background overlay.
   */
  set showBackground(value) {
    if (value) {
      this.setAttribute('show-background', '');
    } else {
      this.removeAttribute('show-background');
    }
  }

  onMounted() {
    this.#overlayRef = this.shadowRoot.querySelector('.overlay');

    this.render();
  }

  /**
   * Called once to return the attributes to observe.
   */
  static get observedAttributes() {
    return ['show'];
  }

  /**
   * Called when it's time to render the component.
   */
  render() {
    if (this.#overlayRef) {
      if (this.show) {
        this.#overlayRef.classList.add('overlay--visible');
      } else {
        this.#overlayRef.classList.remove('overlay--visible');
      }
    }
    return;
  }
}

customElements.define('sk-overlay', Overlay);
export default Overlay;
