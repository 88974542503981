/**
 * Represents the supported breakpoint sizes.
 */
export class BreakpointSize {
  /**
   * Indicates a desktop view.
   */
  static desktop = 'desktop';

  /**
   * Indicates a mobile view.
   */
  static mobile = 'mobile';

  /**
   * Indicates a tablet view.
   */
  static tablet = 'tablet';
}

/**
 * Represents the supported field types for a form.
 */
export class FormFieldType {
  /**
   * Indicates a checkbox field.
   */
  static checkbox = 'checkbox';

  /**
   * Indicates a custom field.
   */
  static custom = 'custom';

  /**
   * Indicates a date field.
   */
  static date = 'date';

  /**
   * Indicates an email field.
   */
  static email = 'email';

  /**
   * Indicates a number field.
   */
  static number = 'number';

  /**
   * Indicates a password field.
   */
  static password = 'password';

  /**
   * Indicates a phone field.
   */
  static phone = 'tel';

  /**
   * Indicates a radio button field.
   */
  static radio = 'radio';

  /**
   * Indicates a dropdown field.
   */
  static select = 'select';

  /**
   * Indicates a textbox field.
   */
  static text = 'text';

  /**
   * Indicates a textarea field.
   */
  static textarea = 'textarea';
}

/**
 * Represents http status codes.
 */
export class HttpStatusCode {
  /**
   * Indicates a created http status.
   */
  static created = 204;

  /**
   * Indicates an internal server error http status.
   */
  static internalServerError = 500;

  /**
   * Indicates a not found http status.
   */
  static notFound = 404;

  /**
   * Indicates an ok http status.
   */
  static ok = 200;

  /**
   * Indicates a request timeout http status.
   */
  static requestTimeout = 408;

  /**
   * Indicates an unauthorized http status.
   */
  static unauthorized = 401;
}

/**
 * Represents keyboard keys.
 */
export class KeyboardKeys {
  /**
   * Indicates the down arrow key.
   */
  static arrowDown = 'ArrowDown';

  /**
   * Indicates the left arrow key.
   */
  static arrowLeft = 'ArrowLeft';

  /**
   * Indicates the right arrow key.
   */
  static arrowRight = 'ArrowRight';

  /**
   * Indicates the up arrow key;
   */
  static arrowUp = 'ArrowUp';

  /**
   * Indicates the backspace key.
   */
  static backspace = 'Backspace';

  /**
   * Indicates the delete key.
   */
  static delete = 'Delete';

  /**
   * Indicates the enter key.
   */
  static enter = 'Enter';

  /**
   * Indicates the shift key.
   */
  static shift = 'Shift';

  /**
   * Indicates the space key.
   */
  static space = ' ';

  /**
   * Indicates the tab key.
   */
  static tab = 'Tab';
}

/**
 * Represents the supported types in JavaScript.
 */
export class Types {
  /**
   * Indicates an array type.
   */
  static array = 'Array';

  /**
   * Indicates a boolean type
   */
  static boolean = 'Boolean';

  /**
   * Indicates a date type.
   */
  static date = 'Date';

  /**
   * Indicates a infinity number type.
   */
  static infinity = 'Infinity';

  /**
   * Indicates a function type.
   */
  static function = 'Function';

  /**
   * Indicates an object type.
   */
  static object = 'Object';

  /**
   * Indicates a null type.
   */
  static null = 'Null';

  /**
   * Indicates a not a number type.
   */
  static nan = 'NaN';

  /**
   * Indicates a number type.
   */
  static number = 'Number';

  /**
   * Indicates a string type.
   */
  static string = 'String';

  /**
   * Indicates an undefined type.
   */
  static undefined = 'Undefined';
}

// Lock object to prevent modification (true static).
Object.freeze(BreakpointSize);
Object.freeze(FormFieldType);
Object.freeze(HttpStatusCode);
Object.freeze(KeyboardKeys);
Object.freeze(Types);
