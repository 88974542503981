import { HttpStatusCode } from '../enums';
import Rest from '../rest';

/**
 * Represents a service for managing API calls to the Locations controller.
 */
class LocationsService {
  /**
   * Returns an array of locations.
   * @returns Promise
   */
  async getLocations() {
    let locations = [];

    try {
      const res = await Rest.get(`${process.env.API_LOCATIONS}/Index`);

      if (res.status === HttpStatusCode.ok) {
        locations = await res.json();
      }
    } catch (error) {
      console.error(error);
    }

    return locations;
  }
}

export default LocationsService;
