const aboutUsView = () => {
  const initLeadershipCardBioModal = () => {
    const readBioButtons = document.querySelectorAll('.leadership-card__bio');

    for (let index = 0; index < readBioButtons.length; ++index) {
      const readBioButton = readBioButtons[index];
      readBioButton.addEventListener('click', () => {
        const leadershipCard = readBioButton.parentElement.parentElement;

        if (!readBioButton.modalRef) {
          const modal = leadershipCard.querySelector('sk-modal');
          const closeModalButton = modal.querySelector('.leadership-card__modal-close');
          closeModalButton.addEventListener('click', () => {
            modal.removeAttribute('show');
          });

          readBioButton.modalRef = modal;
        }

        if (readBioButton.modalRef) {
          readBioButton.modalRef.setAttribute('show', '');
        }
      });
    }
  };
  initLeadershipCardBioModal();
};

export default aboutUsView;
