/**
 * Represents the supported checkbox sizes.
 */
class CheckboxSize {
  /**
   * Indicates a normal checkbox size.
   */
  static normal = 'normal';

  /**
   * Indicates a small checkbox size.
   */
  static small = 'small';
}

export default CheckboxSize;
