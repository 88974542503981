import bemify from '../../utils/bemUtils';
import { scrollIntoView } from '../../utils/domUtils';

const subnavPartial = () => {
  if (!document.querySelector('.subnav')) {
    return;
  }

  const parseFragmentId = fragment => {
    let headingIdAnOffsetY = ['', 0];

    if (fragment) {
      const fragmentIdSplit = fragment.split('#');
      if (fragmentIdSplit.length <= 1) {
        // If there is no fragment, return empty.
        return ['', ''];
      }
      const fragmentId = fragmentIdSplit.at(-1);
      const headingIdWithOffsetSplit = fragmentId.split('-');
      let headingId = fragmentId;
      let offsetY = 0;
      if (headingIdWithOffsetSplit.length > 1) {
        // If your anchor fragment ends with a dash followed by a number, we will assume it's a Y offset in pixels.
        const parsedItem = parseFloat(headingIdWithOffsetSplit.at(-1));
        if (!isNaN(parsedItem)) {
          offsetY = parsedItem;
          headingId = headingId.replace(`-${offsetY}`, '');
        }
      }

      headingIdAnOffsetY = [headingId, offsetY];
    }

    return headingIdAnOffsetY;
  };

  const parseQueryString = () => {
    const response = parseFragmentId(location.hash);
    const [headingId] = response;
    let [, offsetY] = response;

    if (headingId && !isNaN(offsetY)) {
      const heading = document.getElementById(headingId);
      const scrollMargin = parseFloat(window.getComputedStyle(heading).scrollMarginTop);
      const scrollPadding = parseFloat(window.getComputedStyle(heading).scrollPaddingTop);
      if (!offsetY && (scrollMargin > 0 || !isNaN(scrollPadding))) {
        offsetY = isNaN(scrollPadding) ? scrollMargin : scrollMargin + scrollPadding;
      }

      scrollIntoView(heading, offsetY);
    }
  };

  const parseSubnavLinks = () => {
    const subnavLinks = document.querySelectorAll('.subnav__link');

    for (const subnavLink of subnavLinks) {
      const { href } = subnavLink;

      if (href.includes('#')) {
        const response = parseFragmentId(href);
        const [headingId] = response;
        let [, offsetY] = response;

        if (headingId && !isNaN(offsetY)) {
          subnavLink.addEventListener('click', e => {
            e.preventDefault();

            const heading = document.getElementById(headingId);
            const scrollMargin = parseFloat(window.getComputedStyle(heading).scrollMarginTop);
            const scrollPadding = parseFloat(window.getComputedStyle(heading).scrollPaddingTop);
            if (!offsetY && (scrollMargin > 0 || !isNaN(scrollPadding))) {
              offsetY = isNaN(scrollPadding) ? scrollMargin : scrollMargin + scrollPadding;
            }

            scrollIntoView(heading, offsetY);
          });
        }
      }
    }
  };

  parseQueryString();
  parseSubnavLinks();

  // Show/hide arrow navigation.
  const navUl = document.querySelector('.subnav__links');
  const [, element] = bemify('subnav');
  const showArrowClass = 'subnav__arrow--show';
  const scrollStepSize = 120;
  const arrowPrev = document.getElementById(element('arrow', ['prev']));
  arrowPrev?.classList.remove(showArrowClass);
  arrowPrev?.addEventListener('click', () => {
    navUl?.scroll(navUl.scrollLeft - scrollStepSize, 0);
  });
  const arrowNext = document.getElementById(element('arrow', ['next']));
  arrowNext?.classList.remove(showArrowClass);
  arrowNext?.addEventListener('click', () => {
    navUl?.scroll(navUl.scrollLeft + scrollStepSize, 0);
  });
  const toggleNavArrows = () => {
    const overflow = navUl.scrollWidth - navUl.offsetWidth;

    // Left Arrow
    if (navUl.scrollLeft !== 0) {
      arrowPrev?.classList.add(showArrowClass);
    } else {
      arrowPrev?.classList.remove(showArrowClass);
    }

    // Right Arrow
    if (overflow > 0 && navUl.scrollLeft / overflow < 1) {
      arrowNext?.classList.add(showArrowClass);
    } else {
      arrowNext?.classList.remove(showArrowClass);
    }
  };
  toggleNavArrows();
  navUl.addEventListener('scroll', toggleNavArrows);
  window.addEventListener('resize', toggleNavArrows);
};

export default subnavPartial;
