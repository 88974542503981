import bemify from '../../utils/bemUtils';
import Component from '../component/component';

class Step extends Component {
    #line = null;
    #dot1 = null;
    #dot2 = null;

    initTemplate() {
        const [block, element] = bemify('step');
        const template = document.createElement('template');
        template.innerHTML = `
            <style>
                @import url('${process.env.APP_CSS_PATH}');
            </style>
            <div class="${block()}" is-left="${this.isLeft}">
                <div class="${element('left-col')}">
                    ${this.#generateCard()}
                </div>

                <svg class="${element('svg')}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 150" width="100px" height="150px">
                    <line class="${element('svg--line')}" stroke="${this.isLastStep ? 'transparent' : 'gray'}" x1="50%" y1="25" x2="50%" y2="100%" />
                    <line id="line" class="${element('svg--line')}" stroke="${this.isLastStep ? 'transparent' : 'white'}" x1="50%" y1="25" x2="50%" y2="25" />

                    <circle class="${element('svg--dot-1')}" fill="gray" r="7" cx="50%" cy="11"></circle>
                    <circle class="${element('svg--dot-2')}" stroke="gray" r="10" cx="50%" cy="11"></circle>
                </svg>

                <div class="${element('right-col')}">
                    ${this.#generateCard()}
                </div>
            </div>
        `;
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    onMounted() {
        this.#line = this.shadowRoot.querySelector('#line');
        this.#dot1 = this.shadowRoot.querySelector('.step__svg--dot-1');
        this.#dot2 = this.shadowRoot.querySelector('.step__svg--dot-2');

        window.addEventListener('scroll', this.#onScroll.bind(this));
    }

    onUnmounted() {
        window.removeEventListener('scroll', this.#onScroll);
    }

    get icon() {
        return this.getAttribute('icon') ?? '';
    }

    get blueText() {
        return this.getAttribute('blue-text') ?? '';
    }

    get text() {
        return this.getAttribute('text') ?? '';
    }

    get isLastStep() {
        return this.hasAttribute('is-last-step');
    }

    get isLeft() {
        return this.hasAttribute('is-left');
    }

    #generateCard() {
        const [_, element] = bemify('step');

        return `
        <div class="${element('card')}">
            <div class="${element('card__icon')}">
                <span class="material-symbols-outlined">${this.icon}</span>
            </div>

            <div class="${element('card__text')}">
                <span class="${element('card__text--blue')}">${this.blueText}</span>${this.text}
            </div>
        </div>
        `;
    }

    #onScroll() {
        const step = this.getBoundingClientRect();
        const scrollPercentage = this.#clamp((document.documentElement.scrollTop - step.top) / (step.height), 0, 1);

        this.#line.setAttribute('y2', this.#clamp(scrollPercentage * step.height, 25, step.height));

        this.#dot1.setAttribute('fill', scrollPercentage > 0 ? 'white' : 'gray');
        this.#dot2.setAttribute('stroke', scrollPercentage > 0 ? 'white' : 'gray');
    }

    #clamp(value, min, max) {
        return Math.min(Math.max(value, min), max);
    }
}

customElements.define('sk-step', Step);

export default Step;
