/* eslint no-unused-vars: 0 */

/**
 * Represents the base class for components.
 */
class Component extends HTMLElement {
  /**
   * Provides template initialization.
   * @abstract
   */
  initTemplate() {
    throw new Error('initTemplate must be implemented by subclass.');
  }

  /**
   * Called when the component has been added to the DOM.
   */
  connectedCallback() {
    this.initTemplate();
    this.onMounted();
    this.render();
  }

  /**
   * Called when the component has been removed from the DOM.
   */
  disconnectedCallback() {
    this.onUnmounted();
  }

  /**
   * Called every time an observed attribute changes.
   * @param {String} name The property that changed.
   * @param {String} oldValue The old value.
   * @param {String} newValue The new value.
   */
  attributeChangedCallback(name, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.onAttributeChange(name, oldValue, newValue);
      this.render();
    }
  }

  /**
   * Occurs when an attribute changes in a subclass.
   * @param {String} name The property that changed.
   * @param {String} oldValue The old value.
   * @param {String} newValue The new value.
   */
  onAttributeChange(name, oldValue, newValue) {}

  /**
   * Occurs when the component has mounted to the DOM.
   */
  onMounted() {}

  /**
   * Occurs when the component has been unmounted from the DOM.
   */
  onUnmounted() {}

  /**
   * Renders the component.
   * @abstract
   */
  render() {}
}

export default Component;
