/**
 * Represents a HTTP REST client.
 */
class Rest {
  /**
   * Issues an HTTP GET request.
   * @param {String} url The url to the api call.
   * @param {Object} config The request configuration.
   * @returns Promise
   */
  static get(url, config) {
    let cfg = {};

    if (config) {
      cfg = { ...config };
    }

    const options = {
      method: 'GET',
      ...cfg
    };

    return this._request(url, options);
  }

  /**
   * Issues an HTTP POST request.
   * @param {String} url The url to the api call.
   * @param {Object} data The data to pass to the api call.
   * @param {Object} config The request configuration.
   * @returns Promise
   */
  static post(url, data, config) {
    let cfg = {};

    if (config) {
      cfg = { ...config };
    }

    const options = {
      method: 'POST',
      body: JSON.stringify(data || {}),
      ...cfg
    };

    return this._request(url, options);
  }

  /**
   * Issues an HTTP PUT request.
   * @param {String} url The url to the api call.
   * @param {Object} data The data to pass to the api call.
   * @param {Object} config The request configuration.
   * @returns Promise
   */
  static put(url, data, config) {
    let cfg = {};

    if (config) {
      cfg = { ...config };
    }

    const options = {
      method: 'PUT',
      body: JSON.stringify(data || {}),
      ...cfg
    };

    return this._request(url, options);
  }

  /**
   * Issues an HTTP PATCH request.
   * @param {String} url The url to the api call.
   * @param {Object} data The data to pass to the api call.
   * @param {Object} config The request configuration.
   * @returns Promise
   */
  static patch(url, data, config) {
    let cfg = {};

    if (config) {
      cfg = { ...config };
    }

    const options = {
      method: 'PATCH',
      body: JSON.stringify(data || {}),
      ...cfg
    };

    return this._request(url, options);
  }

  /**
   * Issues an HTTP DELETE request.
   * @param {String} url The url to the api call.
   * @param {Object} config The request configuration.
   * @returns Promise
   */
  static delete(url, config) {
    let cfg = {};

    if (config) {
      cfg = { ...config };
    }

    const options = {
      method: 'DELETE',
      ...cfg
    };

    return this._request(url, options);
  }

  static _request(url, options) {
    return new Promise((resolve, reject) => {
      const { timeout } = options;
      const optionsWithSignal = { ...options };
      let abortController = null;

      if (timeout && timeout > 0) {
        abortController = new AbortController();

        const callTimeout = setTimeout(() => {
          clearTimeout(callTimeout);
          abortController.abort();
        }, timeout);

        optionsWithSignal.signal = abortController.signal;
      }

      fetch(url, optionsWithSignal)
        .then(res => resolve(res))
        .catch(error => {
          if (abortController?.signal.aborted) {
            const timeoutError = new Error(`The following call to ${url} timed out.`);

            reject(timeoutError);
          } else {
            reject(error);
          }
        });
    });
  }
}

export default Rest;
