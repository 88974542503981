import Events from '../../events';
import { EventMessages } from '../../constants';
import { debounce } from '../../utils/debounceUtils';

const headerPartial = () => {
  const quarterOfASecond = 250;
  const desktopMenuButton = document.querySelector('.header__menu');
  const mobileMenuButton = document.querySelector('.header__menu--menu');
  const mobileMenuCloseButton = document.querySelector('.header__menu--close');

  const safeWindowResizeCallback = debounce(() => {
    if (mobileMenuButton && mobileMenuCloseButton) {
      mobileMenuButton.classList.remove('header__menu--hidden');
      mobileMenuCloseButton.classList.add('header__menu--hidden');
    }
  }, quarterOfASecond);
  safeWindowResizeCallback();

  const onCloseMobileMenu = () => {
    onToggleMobileButtons();
    Events.emit(EventMessages.closeMobileMenu);
  };

  const onShowDesktopMenu = () => {
    Events.emit(EventMessages.showDesktopMenu);
  };

  const onShowMobileMenu = () => {
    onToggleMobileButtons();
    Events.emit(EventMessages.showMobileMenu);
  };

  const onToggleMobileButtons = () => {
    mobileMenuButton.classList.toggle('header__menu--hidden');
    mobileMenuCloseButton.classList.toggle('header__menu--hidden');
  };

  const unloadResources = () => {
    if (desktopMenuButton) {
      desktopMenuButton.removeEventListener('click', onShowDesktopMenu);
    }

    if (mobileMenuButton) {
      mobileMenuButton.removeEventListener('click', onShowMobileMenu);
    }

    if (mobileMenuCloseButton) {
      mobileMenuCloseButton.removeEventListener('click', onCloseMobileMenu);
    }

    window.removeEventListener('resize', safeWindowResizeCallback);
    window.removeEventListener('beforeunload', unloadResources);
  };

  if (desktopMenuButton) {
    desktopMenuButton.addEventListener('click', onShowDesktopMenu);
  }

  if (mobileMenuButton) {
    mobileMenuButton.addEventListener('click', onShowMobileMenu);
  }

  if (mobileMenuCloseButton) {
    mobileMenuCloseButton.addEventListener('click', onCloseMobileMenu);
  }

  window.addEventListener('resize', safeWindowResizeCallback);
  window.addEventListener('beforeunload', () => unloadResources);
};

export default headerPartial;
