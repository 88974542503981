import bemify from '../../utils/bemUtils';
import Component from '../component/component';
import Rest from '../../rest';

class FacilityLookup extends Component {
    #makeModelsDropdownRef = null;
    #viewButtonRef = null;
    #dateInputRef = null;
    #feesRef = null;
    #disclaimerRef = null;
    #fboFee = null;
    #fbo = null;

    get AirportCode() {
        return this.getAttribute('airport-code') ?? '';
    }

    get CityValue() {
        return this.getAttribute('city-value') ?? '';
    }

    get StateValue() {
        return this.getAttribute('state-value') ?? '';
    }

    initTemplate() {
        const [block, element] = bemify('facility-lookup');
        const template = document.createElement('template');

        template.innerHTML = `
        <style>
            @import url('${process.env.APP_CSS_PATH}');
        </style>

        <section id="${block()}" class="${element('container')}">
            <p class="${element('title', null)}">View Pricing</p>
            <p class="${element('padded-text')}">${this.AirportCode} - ${this.CityValue}, ${this.StateValue}</p>
            <p class="${element('padded-text')}">Search for your aircraft make, model and desired arrival date.</p>
            <div class="${element('row')}">
                <div class="form__group ${element('column')}">
                    <sk-autocomplete id="${element('make-model-dropdown')}"
                                 placeholder="Make & Model">
                    </sk-autocomplete>
                    <span class="form__required-message">Make & Model is required</span>
                </div>

                <div class="form__group ${element('s-column')}">
                    <input id="${element('date-input')}" class="date-input" type="date" min="${new Date()
                .toISOString()
                .slice(0, 10)}" />
                    <span class="form__invalid-message">Arrival Date must be today or greater</span>
                    <span class="form__required-message">Arrival Date is required</span>
                </div>
                <div class="${element('s-column')}">
                  <a id="facility-lookup__view-button" class="button button--secondary ${element('view-button')}">View Prices</a>
                </div>
            </div>

            <div id="facility-lookup__disclaimer" class="${element('disclaimer')}">
                **Prices vary by aircraft type and date of arrival. Prices are subject to change and availability.
            </div>

            <div id="${element('fees')}" class="${element('row')}">
            </div>
        </section>
        `;

        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    onMounted() {
        this.#makeModelsDropdownRef = this.shadowRoot.getElementById('facility-lookup__make-model-dropdown');
        this.#dateInputRef = this.shadowRoot.getElementById('facility-lookup__date-input');
        this.#feesRef = this.shadowRoot.getElementById('facility-lookup__fees');
        this.#viewButtonRef = this.shadowRoot.getElementById('facility-lookup__view-button');
        this.#disclaimerRef = this.shadowRoot.getElementById('facility-lookup__disclaimer');

        Rest.get(`${process.env.API_MAKE_MODELS}/GetAll`).then(async data => {
            if (!data) return;

            const makeModels = await data.text();
            this.#makeModelsDropdownRef.items = makeModels;
        });

        Rest.get(`${process.env.API_FBO}/Get?AirportCode=${this.AirportCode}`).then(async data => {
            if (!data) return;

            this.#fbo = await data.json();
        });

        const onToggleDropdownItemsPosition = (dropdownItems, isOpening) => {
            if (dropdownItems) {
                if (isOpening) {
                    dropdownItems.classList.add('dropdown__items--no-absolute');
                } else {
                    dropdownItems.classList.remove('dropdown__items--no-absolute');
                }
            }
        };

        this.#makeModelsDropdownRef.addEventListener('on-before-open', ({ detail: dropdownItems }) =>
            onToggleDropdownItemsPosition(dropdownItems, true)
        );
        this.#makeModelsDropdownRef.addEventListener('on-before-close', ({ detail: dropdownItems }) =>
            onToggleDropdownItemsPosition(dropdownItems, false)
        );
        this.#makeModelsDropdownRef.addEventListener('on-change', () => {
            if (this.#makeModelsDropdownRef.value?.length >= 0 && this.#makeModelsDropdownRef.value !== 'undefined') {
                this.#clearFieldErrors(this.#makeModelsDropdownRef);
            }
        }
        );
        this.#dateInputRef.addEventListener('change', () => {
            if (this.#dateInputRef.value?.length >= 0 && this.#dateInputRef.value !== 'undefined') {
                this.#clearFieldErrors(this.#dateInputRef);
            }
        }
        );
        this.#viewButtonRef.addEventListener('click', async () => await this.#getFees());
    }

    render() {
        const [_, element] = bemify('facility-lookup');
        this.#feesRef.innerHTML = '';
        const dollar = new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        });

        if (!this.#fboFee) return;

        let noFeesToShow = true;

        if (this.#fboFee.facFeeAmounts.length > 0) {
            noFeesToShow = false;
            this.#feesRef.innerHTML += `
            <div class="${element('fee')}" span-col="2">
                <h4 class="${element('label', null)}">Facility Fee</h4>
                <div class="${element('text')}">${
                  this.#fboFee.facFeeAmounts.length > 1
                    ? 'Please contact base.'
                    : `<p>${dollar.format(
                        this.#fboFee.facilityFee.unitPrice
                      )}</p><p>${dollar.format(this.#fboFee.facFeeAmounts[0].facFeeAmount)} with ${
                        this.#fboFee.facFeeAmounts[0].beginGals
                    } gallons or more</p>`
                }</div>
            </div>
            `;
        } else if (this.#fboFee.facilityFee?.unitPrice > 0) {
            noFeesToShow = false;
            this.#feesRef.innerHTML += `
            <div class="${element('fee')}">
                <h4 class="${element('label', null)}">Facility Fee</h4>
                <p class="${element('text')}">${dollar.format(this.#fboFee.facilityFee.unitPrice)}</p>
            </div>
            `;

            if (
                ((this.#fboFee.fuelProductId == 1 || this.#fboFee.isMultiFuelAircraft) && this.#fboFee.isJetAOffered) || // JETA = 1
                (this.#fboFee.fuelProductId == 2 && !this.#fboFee.isMultiFuelAircraft && this.#fboFee.is100LLOffered)
            ) {
                noFeesToShow = false;
                // 100LL = 2
                this.#feesRef.innerHTML += `
                <div class="${element('fee')}">
                    <h4 class="${element('label', null)}">Gallons Needed to Waive Facility Fee</h4>
                    <p class="${element('text')}">${
                      this.#fboFee.gallonsToWaive.unitPrice <= 0
                        ? 'Please contact base'
                        : this.#fboFee.gallonsToWaive.unitPrice
                    }</p>
                </div>
                `;
            } else if (this.#fboFee.facilityFee?.unitPrice > 0) {
                noFeesToShow = false;
                this.#feesRef.innerHTML += `
                <div class="${element('fee')}">
                    <h4 class="${element('label', null)}">Gallons Needed to Waive Facility Fee</h4>
                    <p class="${element('text')}">${
                      this.#fbo
                        ? `Please contact the base directly at <a href="tel: ${this.#fbo.phone}">${
                            this.#fbo.phone
                        }</a> for further information.`
                        : 'Please contact the base directly for further information.'
                    }</p>
                </div>
                `;
            }
        }

        if (this.#fboFee.securityFee?.unitPrice > 0) {
            noFeesToShow = false;
            this.#feesRef.innerHTML += `
            <div class="${element('fee')}">
                <h4 class="${element('label', null)}">Security Fee</h4>
                <p class="${element('text')}">${dollar.format(this.#fboFee.securityFee.unitPrice)}</p>
            </div>
            `;
        }


        if (this.#fboFee.specialEventFee?.unitPrice > 0) {
            noFeesToShow = false;
            this.#feesRef.innerHTML += `
            <div class="${element('fee')}">
                <h4 class="${element('label', null)}">Special Event Fee</h4>
                <p class="${element('text')}">${dollar.format(this.#fboFee.specialEventFee.unitPrice)}</p>
            </div>
            `;
        }

        if (!this.#feesRef.isMultiFuelAircraft && this.#fboFee.habitatFee?.unitPrice > 0) {
            noFeesToShow = false;
            this.#feesRef.innerHTML += `
            <div class="${element('fee')}">
                <h4 class="${element('label', null)}">Habitat Fee</h4>
                <p class="${element('text')}">${dollar.format(this.#fboFee.habitatFee.unitPrice)}</p>
            </div>
            `;
        }

        if (this.#fboFee.parking?.unitPrice > 0 && this.AirportCode.toUpperCase() != '6N5') {
            noFeesToShow = false;
            this.#feesRef.innerHTML += `
            <div class="${element('fee')}">
                <h4 class="${element('label', null)}">Parking</h4>
                <p class="${element('text')}">${dollar.format(this.#fboFee.parking.unitPrice)}</p>
            </div>
            `;
        }

        if (this.#fboFee.hangar?.unitPrice > 0) {
            noFeesToShow = false;
            this.#feesRef.innerHTML += `
            <div class="${element('fee')}">
                <h4 class="${element('label', null)}">Hangar</h4>
                <p class="${element('text')}">${dollar.format(this.#fboFee.hangar.unitPrice)}</p>
            </div>
            `;
        }

        if (this.#fboFee.hasAirportFees) {
            noFeesToShow = false;
            this.#disclaimerRef.innerHTML = `<p>**Prices vary by aircraft type and date of arrival. Prices are subject to change and availability. Airport mandated fees may apply.</p>
            `;

        } else {
            this.#disclaimerRef.innerHTML = '**Prices vary by aircraft type and date of arrival. Prices are subject to change and availability.';
        }

        if (noFeesToShow) {
            this.#feesRef.innerHTML += `
            <div>
                <p class="${element('text', null)}">${this.#fbo
                    ? `Details are not available for aircraft and date selected. Please call us directly at <a href="tel: ${this.#fbo.phone}">${this.#fbo.phone
                    }</a> for more information.`
                    : 'Details are not available for aircraft and date selected.Please call the base for more information.'
                }</p>
            </div>
            `;
        }

        this.#feesRef.classList.add('facility-lookup__fee-row');
    }

    #clearFieldErrors(field) {
        const requiredMessage = field.parentNode.querySelector('.form__required-message--visible');
        const invalidMessage = field.parentNode.querySelector('.form__invalid-message--visible');

        if (requiredMessage) requiredMessage.classList.remove('form__required-message--visible');
        if (invalidMessage) invalidMessage.classList.remove('form__invalid-message--visible');
    };

    #clearErrors() {
        const requiredMessages = document.querySelectorAll('.form__required-message');

        for (let index = 0; index < requiredMessages?.length; ++index) {
            const requiredMessage = requiredMessages[index];

            if (requiredMessage) {
                requiredMessage.classList.remove('form__required-message--visible');
            }
        }

        const invalidMessages = document.querySelectorAll('.form__invalid-message');

        for (let index = 0; index < invalidMessages?.length; ++index) {
            const invalidMessage = invalidMessages[index];

            if (invalidMessage) {
                invalidMessage.classList.remove('form__invalid-message--visible');
            }
        }
    };

    #showErrorMessage(field, group, errorClass, errorClassVisible, shouldFocus = true) {
        const errorMessage = group?.querySelector(`.${errorClass}`);

        if (errorMessage) {
            field.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start'
            });
            if (shouldFocus) field.focus();
            errorMessage.classList.add(errorClassVisible);
        }
    };

    #validate() {
        this.#clearErrors();

        let hasErrors = false;

        if (!this.AirportCode) {
            hasErrors = true;
        }

        if (this.#dateInputRef.value?.length <= 0 || this.#dateInputRef.value === 'undefined') {
            hasErrors = true;
            this.#showErrorMessage(
                this.#dateInputRef,
                this.#dateInputRef.parentElement,
                'form__required-message',
                'form__required-message--visible',
                false
            );
        } else {
            const now = new Date();
            now.setHours(0, 0, 0, 0);

            const dateInput = new Date(`${this.#dateInputRef.value}T00:00`);
            dateInput.setHours(0, 0, 0, 0);

            if (dateInput < now) {
                hasErrors = true;
                this.#showErrorMessage(
                    this.#dateInputRef,
                    this.#dateInputRef.parentElement,
                    'form__invalid-message',
                    'form__invalid-message--visible',
                    false
                );
            }
        }

        if (this.#makeModelsDropdownRef.value?.length <= 0 || this.#makeModelsDropdownRef.value === 'undefined') {
            hasErrors = true;
            this.#showErrorMessage(
                this.#makeModelsDropdownRef,
                this.#makeModelsDropdownRef.parentElement,
                'form__required-message',
                'form__required-message--visible'
            );
        }

        return !hasErrors;
    };

    async #getFees() {
        if (!this.#validate()) {
            return;
        }

        const response = await Rest.get(
      `${process.env.API_FACILITY_LOOKUP}/Get?aiportCode=${this.AirportCode}&makeModelid=${
        this.#makeModelsDropdownRef.value
      }&arrivalDate=${this.#dateInputRef.value}`
        );
        this.#fboFee = await response.json();
        this.render();
    }
}

customElements.define('sk-facility-lookup', FacilityLookup);

export default FacilityLookup;
