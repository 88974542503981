/**
 * Represents a collection of strongly typed event messages.
 */
export class EventMessages {
  /**
   * Indicates a message to close the desktop menu.
   */
  static closeDesktopMenu = 'close-desktop-menu';

  /**
   * Indicates a message to show the desktop menu.
   */
  static showDesktopMenu = 'show-desktop-menu';

  /**
   * Indicates a message to close the desktop menu.
   */
  static closeMobileMenu = 'close-mobile-menu';

  /**
   * Indicates a message that the maps api has initilized.
   */
  static mapsInit = 'maps-init';

  /**
   * Indicates a message that the maps api is ready to use.
   */
  static mapsReady = 'maps-ready';

  /**
   * Indicates a message that recaptcha is ready to validate.
   */
  static recaptchaValidate = 'recaptcha-validate';

  /**
   * Indicates a message to show the desktop menu.
   */
  static showMobileMenu = 'show-mobile-menu';
}

/**
 * Represents strongly typed map constants.
 */
export class MapConstants {
  /**
   * The default zoom level.
   */
  static defaultZoom = 5;

  /**
   * The zoom level when focused on a marker.
   */
  static markerZoom = 7.5;

  /**
   * The maximum zoom level.
   */
  static maxZoom = 8;

  /**
   * The minimum zoom level.
   */
  static minZoom = 3;

  /**
   * The center of Oklahoma.
   */
  static usCenter = { lat: 36.084621, lng: -96.921387 };
}

// Lock object to prevent modification (true static).
Object.freeze(MapConstants);
Object.freeze(EventMessages);
