import bemify from '../../utils/bemUtils';
import { debounce } from '../../utils/debounceUtils';

/**
 * Represents a carousel component.
 */
class Carousel extends HTMLElement {
  #currSlideIndex = 0;
  #slotRef = null;
  #slidesRef = null;
  #slideRangeRef = null;
  #rangeCurrRef = null;
  #rangeEndRef = null;
  #prevButton = null;
  #nextButton = null;
  #safeWindowResize = null;

  /**
   * Initializes a new instance of the Carousel class.
   */
  constructor() {
    super();

    const [block, element] = bemify('carousel');
    const template = document.createElement('template');
    template.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <div class="${block()}">
        <ul class="${element('slides')}">
          <slot />
        </ul>
        <div class="${element('controls')}">
          <div class="${element('range')}"></div>
          <div>
            <span class="${element('range-curr')}">00</span>
            <span class="${element('range-end')}">00</span>
          </div>
          <div class="${element('arrows')}">
            <button
              class="${element('prev')}"
              type="button">
              <span class="material-symbols-outlined">chevron_left</span>
            </button>
            <button
              class="${element('next')}"
              type="button">
              <span class="material-symbols-outlined">chevron_right</span>
            </button>
          </div>
        </div>
      </div>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(template.content.cloneNode(true));
  }

  /**
   * Called when the component has been added to the DOM.
   */
  connectedCallback() {
    this.#slotRef = this.shadowRoot.querySelector('slot');
    this.#slidesRef = this.shadowRoot.querySelector('.carousel__slides');
    this.#slidesRef.addEventListener('scrollend', () => this.#onSlide());
    this.#slideRangeRef = this.shadowRoot.querySelector('.carousel__range');
    this.#rangeCurrRef = this.shadowRoot.querySelector('.carousel__range-curr');
    this.#rangeEndRef = this.shadowRoot.querySelector('.carousel__range-end');
    this.#prevButton = this.shadowRoot.querySelector('.carousel__prev');
    this.#prevButton.addEventListener('click', () => this.#slideIntoView(this.#currSlideIndex - 1));
    this.#nextButton = this.shadowRoot.querySelector('.carousel__next');
    this.#nextButton.addEventListener('click', () => this.#slideIntoView(this.#currSlideIndex + 1));
    this.#safeWindowResize = debounce(() => {
      // We need to reset the index and slide when the window changes.
      // The carousel will at times show the wrong slide if not reset.
      this.#currSlideIndex = 0;
      this.#slideIntoView(this.#currSlideIndex);
    }, 250);

    window.addEventListener('resize', this.#safeWindowResize);

    this.#initSlideRange();

    this.#slideIntoView(this.#currSlideIndex);
  }

  /**
   * Called when the component has been removed from the DOM.
   */
  disconnectedCallback() {
    window.removeEventListener('resize', this.#safeWindowResize);
  }

  /**
   * Called when it's time to render the component.
   */
  render() {
    const numSlides = this.#slotRef.assignedElements().length;

    for (let index = 0; index < numSlides; ++index) {
      const rangeIndicator = this.#slideRangeRef.children[index];

      if (rangeIndicator.classList.contains('carousel__range-indicator--active')) {
        rangeIndicator.classList.remove('carousel__range-indicator--active');
      }

      if (index === this.#currSlideIndex) {
        rangeIndicator.classList.add('carousel__range-indicator--active');
      }
    }

    this.#rangeCurrRef.textContent = `0${this.#currSlideIndex + 1}`;
    this.#rangeEndRef.textContent = ` - 0${numSlides}`;
  }

  #initSlideRange() {
    const numSlides = this.#slotRef.assignedElements().length;

    for (let index = 0; index < numSlides; ++index) {
      const rangeIndicator = document.createElement('span');
      rangeIndicator.classList.add('carousel__range-indicator');

      if (index === 0) {
        rangeIndicator.classList.add('carousel__range-indicator--active');
      }

      this.#slideRangeRef.appendChild(rangeIndicator);
    }
  }

  #onSlide() {
    const slotChildren = this.#slotRef?.assignedElements();
    const slideOffset = slotChildren?.[0]?.clientWidth;
    const newIndex = Math.round(this.#slidesRef?.scrollLeft / slideOffset);
    if (!isNaN(newIndex) && newIndex < Infinity) {
      this.#slideIntoView(newIndex);
    }
  }

  #slideIntoView(newIndex) {
    let updatedIndex = newIndex;
    const slotChildren = this.#slotRef.assignedElements();
    const numSlides = slotChildren.length;

    if (updatedIndex < 0) {
      updatedIndex = 0;
    } else if (updatedIndex >= numSlides) {
      updatedIndex = numSlides - 1;
    }

    const slideNode = slotChildren[updatedIndex];
    const slideOffset = slideNode?.clientWidth;

    this.#slidesRef.scrollLeft = slideOffset * newIndex;

    this.#currSlideIndex = updatedIndex;

    this.render();
  }
}

customElements.define('sk-carousel', Carousel);
export default Carousel;
