import Events from '../events';
import { EventMessages } from '../constants';
import LocationsService from '../services/locationsService';

const masterView = () => {
  let locations = null;
  const menu = document.getElementById('menu');
  const menuCloseButton = document.querySelector('.master__menu-close');
  const locationSearch = document.querySelector('#location-search');

  const getLocations = async () => {
    const locationService = new LocationsService();
    locations = await locationService.getLocations();

    initLocationSearch();
  };
  getLocations();

  const initLocationSearch = () => {
    const items = [];

    for (let index = 0; index < locations?.length; ++index) {
      const location = locations[index];
      const { airportCode, city, state } = location;
      const item = {
        label: `${airportCode} - ${city}, ${state}`,
        value: airportCode
      };

      items.push(item);
    }

    if (locationSearch) {
      locationSearch.items = JSON.stringify(items);
    }
  };

  const onCloseMenu = () => {
    if (menu) {
      menu.show = false;
    }
  };

  const onNavigateToLocation = airportCode => {
    if (!airportCode) {
      return;
    }
    window.location.href = `/locations/${airportCode}`;
  };

  const onShowMenu = () => {
    if (menu) {
      menu.show = true;
    }
  };

  const unloadResources = () => {
    if (menuCloseButton) {
      menuCloseButton.removeEventListener('click', onCloseMenu);
    }

    Events.removeListener(EventMessages.showDesktopMenu, onShowMenu);
    Events.removeListener(EventMessages.closeDesktopMenu, onCloseMenu);
    window.removeEventListener('beforeunload', unloadResources);
  };

  if (menuCloseButton) {
    menuCloseButton.addEventListener('click', onCloseMenu);
  }

  if (locationSearch) {
    locationSearch.addEventListener('on-change', ({ detail }) => {
      const { value, reason } = detail;

      if (reason === 'itemSelected') {
        onNavigateToLocation(value);
      }
    });
  }

  const fboSearches = document.querySelectorAll('[id^="fbo-search-"]');

  // Wire up any fbo search components on the page, so
  // that content authors can drop them on any content page.
  for (let index = 0; index < fboSearches?.length; ++index) {
    const fboSearch = fboSearches[index];
    fboSearch.addEventListener('on-change', ({ detail }) => {
      const { value, reason } = detail;

      if (reason === 'itemSelected') {
        onNavigateToLocation(value);
      }
    });
  }

  Events.on(EventMessages.showDesktopMenu, onShowMenu);
  Events.on(EventMessages.closeDesktopMenu, onCloseMenu);
  Events.on(EventMessages.showMobileMenu, onShowMenu);
  Events.on(EventMessages.closeMobileMenu, onCloseMenu);
  window.addEventListener('beforeunload', unloadResources);
};

export default masterView;
