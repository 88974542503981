﻿import { KeyboardKeys } from '../../enums';
import bemify from '../../utils/bemUtils';
import { debounce } from '../../utils/debounceUtils';
import Component from '../component/component';

class Autocomplete extends Component {
    //#region Fields

    #autocompleteInputRef = null;
    #currActiveIndex = -1;
    #dropdownItemsRef = null;
    #items = [];

    //#endregion Fields

    //#region Properties

    /**
     * Returns true if this autocomplete is disabled, otherwise false.
     */
    get disabled() {
        return this.hasAttribute('disabled');
    }

    /**
     * Sets the disabled attribute for both the autocomplete and child input elements.
     */
    set disabled(value) {
        this.#syncAttributes('disabled', value);
    }

    /**
     * Returns the value of the child input element.
     */
    get displayedValue() {
        return this.#autocompleteInputRef.value;
    }

    /**
     * Sets the value of the child input element.
     */
    set displayedValue(value) {
        this.#autocompleteInputRef.value = value;
    }

    /**
     * Returns the 'icon' attribute for this autocomplete.
     */
    get icon() {
        return this.getAttribute('icon') ?? '';
    }

    /**
     * Returns the ID of the child input element.
     */
    get inputId() {
        return this.getAttribute('inputId') ?? '';
    }

    /**
     * Returns the 'name' attribute of the child input element.
     */
    get inputName() {
        return this.getAttribute('inputName') ?? '';
    }

    /**
     * Returns the items collection for this autocomplete.
     */
    get items() {
        return this.getAttribute('items') ?? '[]';
    }

    /**
     * Populates the items collection for this autocomplete.
     * You can provide items either as an array of objects, each object having a 'label' and 'value' property:
     * ('[{ "label": "MDW", "value": "13"}, { "label": ... ')
     * or you can provide items as an array of strings:
     * ('[ 'value1', 'value2', ... ')
     */
    set items(value) {
        this.setAttribute('items', value);
    }

    /**
     * Called once to return the attributes to observe.
     */
    static get observedAttributes() {
        return ['items', 'open', 'disabled'];
    }

    /**
     * Returns the placeholder for this autocomplete.
     */
    get placeholder() {
        return this.getAttribute('placeholder') ?? '';
    }

    /**
     * Sets the placeholder for both the autocomplete and child input elements.
     */
    set placeholder(value) {
        this.setAttribute('placeholder', value);
        this.#setInputPlaceholder();
    }

    /**
     * Returns the placeholder to use for mobile devices for this autocomplete.
     */
    get mobilePlaceholder() {
        return this.getAttribute('mobile-placeholder') ?? '';
    }

    /**
     * Sets the placeholder to use for mobile devices for this autocomplete.
     */
    set mobilePlaceholder(value) {
        this.setAttribute('mobile-placeholder', value);
        this.#setInputPlaceholder();
    }

    /**
     * Returns the 'mobile-placeholder-breakpoint' attribute for this autocomplete, which designates the max screen width (in pixels) for displaying the mobile placeholder in favor of the standard placeholder.
     */
    get mobilePlaceholderBreakpoint() {
        return +(this.getAttribute('mobile-placeholder-breakpoint') ?? 768); // $screen-md
    }

    /**
     * Whether or not the items drop-down is currently open.
     */
    get open() {
        return this.hasAttribute('open');
    }

    /**
     * Opens (true) or closes (false) the items drop-down.
     */
    set open(value) {
        if (value) {
            this.dispatchEvent(new CustomEvent('on-before-open', { detail: this.#dropdownItemsRef }));
            this.setAttribute('open', '');
        } else {
            this.dispatchEvent(new CustomEvent('on-before-close', { detail: this.#dropdownItemsRef }));
            this.removeAttribute('open');
        }
    }

    /**
     * Returns the 'readonly' attribute of the child input element.
     */
    get readonly() {
        return this.getAttribute('readonly') ?? '';
    }

    /**
     * Sets the readonly attribute for both the autocomplete and child input elements.
     */
    set readonly(value) {
        this.#syncAttributes('readonly', value);
    }

    /**
     * Returns the value for this autocomplete.
     */
    get value() {
        return this.getAttribute('value') ?? '';
    }

    /**
     * Sets the value of this autocomplete element and raises an 'on-change' event.
     */
    set value(value) {
        this.setAttribute('value', value);
    }

    //#endregion Properties

    //#region Inherited Methods

    /**
     * Provides pass-through call to text input validity check.
     * @returns true if text input state is valid, otherwise false.
     */
    checkValidity() {
        return this.#autocompleteInputRef.checkValidity();
    }

    initTemplate() {
        const [block, element] = bemify('autocomplete');

        const template = document.createElement('template');
        template.innerHTML = `
        <style>
            @import url('${process.env.APP_CSS_PATH}');
        </style>
        <div class="${block()}">
            <input class="${element('input')}" type="text" />
            <ul class="${element('items')}" tabindex="-1"></ul>
        </div>
        `;

        let shadowRoot = this.shadowRoot;
        if (!shadowRoot) {
            shadowRoot = this.attachShadow({ mode: 'open' });
        }

        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    onMounted() {
        this.#autocompleteInputRef = this.shadowRoot.querySelector('.autocomplete__input');

        if (this.#autocompleteInputRef) {
            this.#autocompleteInputRef.addEventListener('focus', () => this.#onFocus());
            this.#autocompleteInputRef.addEventListener('keydown', e => this.#onKeyDown(e));
            this.#autocompleteInputRef.addEventListener('input', e => this.#onInput(e.target.value));
            this.#autocompleteInputRef.addEventListener('click', e => {
                if (e.target === this.#autocompleteInputRef && !this.disabled && !this.readonly) {
                    this.#onClick();
                }
            });

            if (this.inputId) {
                this.#autocompleteInputRef.id = this.inputId;
            }
            if (this.inputName) {
                this.#autocompleteInputRef.name = this.inputName;
            }

            if (this.hasAttribute('required')) {
                this.#autocompleteInputRef.setAttribute('required', '');
            }

            this.disabled = this.hasAttribute('disabled');
            this.readonly = this.hasAttribute('readonly');
            this.#setInputPlaceholder();
        }
        this.#dropdownItemsRef = this.shadowRoot.querySelector('.autocomplete__items');

        const quarterOfASecond = 250;
        window.addEventListener(
            'resize',
            debounce(
                () => {
                    this.render();
                },
                quarterOfASecond,
                true
            )
        );

        // Close on click-away.
        document.addEventListener('click', e => {
            const target = e.composedPath()[0];
            if (target !== this && !this.shadowRoot.contains(target)) {
                this.#onClose();
            }
        });

        // Close on 'ESC' key.
        document.addEventListener('keydown', e => {
            if (e.key === KeyboardKeys.esc) {
                this.#onClose();
            }
        });
    }

    render() {
        this.#items = JSON.parse(this.items);

        if (this.#dropdownItemsRef && this.#autocompleteInputRef?.value.length === 0) {
            this.#loadItems(this.#items);
        }

        if (this.open) {
            this.#autocompleteInputRef?.classList.add('autocomplete__dropdown--opened');
            this.#dropdownItemsRef?.classList.add('autocomplete__items--visible');
        } else {
            this.#autocompleteInputRef?.classList.remove('autocomplete__dropdown--opened');
            this.#dropdownItemsRef?.classList.remove('autocomplete__items--visible');
        }

        if (this.disabled) {
            this.#autocompleteInputRef?.setAttribute('disabled', '');
        } else {
            this.#autocompleteInputRef?.removeAttribute('disabled');
        }

        this.#setInputPlaceholder();

        return;
    }

    //#endregion Inherited Methods

    //#region Methods

    #adjustItemsPosition() {
        const dropdownBounds = this.#autocompleteInputRef.getBoundingClientRect();
        const dropdownItemsBounds = this.#dropdownItemsRef.getBoundingClientRect();

        if (dropdownBounds.bottom + dropdownItemsBounds.height >= document.documentElement.clientHeight) {
            this.#dropdownItemsRef.style.bottom = '105%';
            this.#dropdownItemsRef.style.top = 'unset';
        } else {
            this.#dropdownItemsRef.style.top = '105%';
            this.#dropdownItemsRef.style.bottom = 'unset';
        }
    }

    #loadItems(items, highlightFirstItem = false) {
        this.#dropdownItemsRef.innerHTML = '';

        for (let index = 0; index < items?.length; ++index) {
            const item = items[index];
            const itemLabel = document.createElement('span');
            const label = (!!item.label ? item.label : item).toString();
            const value = (!!item.value ? item.value : item).toString();
            itemLabel.textContent = label;

            const span = document.createElement('span');
            span.textContent = label;

            const listItem = document.createElement('button');
            listItem.classList.add('autocomplete__item');
            listItem.setAttribute('tabindex', '-1');
            listItem.addEventListener('click', () => this.#onItemClick(listItem, value));
            listItem.appendChild(span);

            if (highlightFirstItem && index === 0) {
                this.#currActiveIndex = index;
                listItem.classList.add('autocomplete__item--active');
            }

            const li = document.createElement('li');
            li.appendChild(listItem);
            li.setAttribute('data-item-value', item.value);

            this.#dropdownItemsRef.appendChild(li);
        }
    }

    #onChange(value, reason) {
        const event = new CustomEvent('on-change', {
            detail: {
                value: value,
                reason: reason
            }
        });

        this.dispatchEvent(event);
    }

    #onClick() {
        if (!this.open) {
            this.#adjustItemsPosition();
        } else {
            this.#onUnfocus();
        }
        this.open = !this.open;
    }

    #onClose() {
        this.open = false;
        this.#currActiveIndex = -1;
        this.#onUnfocus();
    }

    #onFocus() {
        const event = new CustomEvent('on-focus');

        this.dispatchEvent(event);
    }

    #onInput(value) {
        this.#onChange(value, 'input');
        this.#dropdownItemsRef.innerHTML = '';

        if (!value) {
            this.open = false;
            return;
        }

        const escapedValue = value.replace('+', '\\+');
        const regex = new RegExp(escapedValue.toUpperCase());

        const exactMatchItems = JSON.parse(this.items).filter(item => {
            // '[ 'value1', 'value2', ... '
            if (!item.label) {
                return item.toString().toUpperCase().startsWith(value.toUpperCase());
            }

            // '[{ "label": "MDW", "value": "13"}, { "label": ... '
            return item.label.toString().toUpperCase().startsWith(value.toUpperCase()) || item.value.toString().toUpperCase().startsWith(value.toUpperCase());
        });

        const matchItems = JSON.parse(this.items).filter(item => {
            if (exactMatchItems.some(i => JSON.stringify(i) === JSON.stringify(item))) {
                return false;
            }

            // '[ 'value1', 'value2', ... '
            if (!item.label) {
                return regex.test(item.toString().toUpperCase());
            }

            // '[{ "label": "MDW", "value": "13"}, { "label": ... '
            return regex.test(item.label.toString().toUpperCase()) || regex.test(item.value.toString().toUpperCase());
        });

        const items = exactMatchItems.concat(matchItems);

        this.#loadItems(items, true);
        this.open = items.length > 0;
        this.#adjustItemsPosition();
    }

    #onItemClick(button, value) {
        button.blur();
        if (value) {
            button.classList.add('autocomplete__item--active');
            this.displayedValue = button.textContent;
            this.#setValue(value, 'itemSelected');
            this.#onClose();
        }
    }

    #onKeyDown(e) {
        const { key } = e;
        let open = false;

        switch (key) {
            case KeyboardKeys.arrowDown:
                e.preventDefault();
                open = true;
                this.#currActiveIndex = Math.min(this.#currActiveIndex + 1, this.#dropdownItemsRef?.childNodes.length - 1);
                break;
            case KeyboardKeys.arrowUp:
                e.preventDefault();
                open = true;
                this.#currActiveIndex = Math.max(this.#currActiveIndex - 1, 0);
                break;
            case KeyboardKeys.enter: {
                e.preventDefault();
                if (this.#currActiveIndex >= 0) {
                    const item = this.#dropdownItemsRef?.childNodes[this.#currActiveIndex];
                    this.#onItemClick(item, item.dataset.itemValue);
                }
                break;
            }
            case KeyboardKeys.tab: {
                this.#loadItems(JSON.parse(this.items));
                this.#onClose();
                break;
            }
        }

        if (open) {
            this.open = true;
            this.#updateItemFocus();
        }
    }

    /**
     * On unfocus (e.g. tab-away, click-away, etc.), clear out display and value if it doesn't match an allowed option.
     */
    #onUnfocus() {
        // No changes if un-editable.
        if (this.disabled || this.readonly) {
            return;
        }

        const hasValidInput = JSON.parse(this.items).some(item => {
            let itemDisplay = null;
            let itemValue = null;

            if (!item.label) {
                // '[ 'value1', 'value2', ... '
                itemDisplay = item.toString().toUpperCase();
                itemValue = item.toString();
            } else {
                // '[{ "label": "MDW", "value": "13"}, { "label": ... '
                itemDisplay = item.label.toString().toUpperCase();
                itemValue = item.value.toString();
            }

            const isDisplayMatch = itemDisplay === this.displayedValue.toUpperCase();
            let isValueMatch = itemValue.toUpperCase() === this.value.toUpperCase();

            if (isDisplayMatch && !isValueMatch) {
                this.#setValue(itemValue, 'itemSelected');
                isValueMatch = itemValue.toUpperCase() === this.value.toUpperCase();
            }

            return isDisplayMatch && isValueMatch;
        });

        if (!hasValidInput) {
            this.displayedValue = '';
            this.#setValue(undefined, 'cleared');
        }
    }

    #setValue(value, reason) {
        this.value = value;
        if (reason) {
            this.#onChange(value, reason);
        }
    }

    #updateItemFocus() {
        let foundFocusedItem = null;

        for (let index = 0; index < this.#dropdownItemsRef?.childNodes.length; ++index) {
            const listItem = this.#dropdownItemsRef?.childNodes[index];
            const listItemButton = listItem.childNodes[0];

            if (index === this.#currActiveIndex) {
                listItemButton.classList.add('autocomplete__item--active');

                foundFocusedItem = listItem;
            } else {
                listItemButton.classList.remove('autocomplete__item--active');
            }
        }

        if (foundFocusedItem) {
            foundFocusedItem.focus();
            foundFocusedItem.scrollIntoView({ block: 'nearest' });
        }
    }

    /**
     * Sets the placeholder for the child input element.
     */
    #setInputPlaceholder() {
        if (this.#autocompleteInputRef) {
            this.#autocompleteInputRef.placeholder =
                window.innerWidth > this.mobilePlaceholderBreakpoint
                    ? this.placeholder
                    : this.mobilePlaceholder || this.placeholder;
        }
    }

    /**
     * Sets the given attribute to the given value for both this autocomplete element AND the child input element.
     * @param {string} name The name of the attribute to set for both elements.
     * @param {*} value The value to set the attribute to for both elements.
     */
    #syncAttributes(name, value) {
        if (value) {
            this.setAttribute(name, value);
            this.#autocompleteInputRef.setAttribute(name, value);
        } else {
            this.removeAttribute(name);
            this.#autocompleteInputRef.removeAttribute(name);
        }
    }

    //#endregion Methods
}

customElements.define('sk-autocomplete', Autocomplete);
export default Autocomplete;
