/**
 * Represents an information window for a map marker.
 */
class InfoWindow {
  #infoWindow = null;

  /**
   * Initializes a new instance of the InfoWindow class.
   */
  constructor() {
    this.#initInfoWindow();
  }

  /**
   * Closes the info window.
   */
  close() {
    this.#infoWindow?.close();
  }

  /**
   * Opens the info window.
   * @param {Object} map The map to show the info window on.
   * @param {Object} marker The marker to show the info window for.
   */
  open(map, marker) {
    this.#infoWindow.open(map.map, marker.marker);
  }

  /**
   * Sets the content to show inside the info window.
   * @param {Any} content The content to show in the info window.
   */
  setContent(content) {
    this.#infoWindow?.setContent(content);
  }

  #initInfoWindow() {
    const { GInfoWindow } = window.aa;
    this.#infoWindow = new GInfoWindow();
  }
}

export default InfoWindow;
