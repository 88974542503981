const cardCarouselPartial = () => {
  if (!document.querySelector('.splide')) {
    return;
  }

  const onToggleImageModal = imageUrl => {
    if (cardImageModal && cardImage) {
      cardImageModal.show = !cardImageModal.show;
      cardImage.src = imageUrl;
    }
  };

  const cardImageModal = document.getElementById('card-image-modal');
  const cardImage = cardImageModal?.querySelector('[data-modal-card-image]');
  const closeModal = cardImageModal?.querySelector('[data-modal-close]');
  closeModal?.addEventListener('click', () => onToggleImageModal());

  const imageCarouselCards = document.querySelectorAll('[data-image-url]');

  for (const card of imageCarouselCards) {
    card.addEventListener('click', () => onToggleImageModal(card.dataset.imageUrl));
  }
};

export default cardCarouselPartial;
