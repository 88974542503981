import bemify from '../utils/bemUtils';

const realEstateView = () => {
  if (!document.getElementById('realEstatePage')) {
    return;
  }

  const [, element] = bemify('realEstate');

  const locationsFilter = document.getElementById(element('locations-filter'));
  const clearButton = document.getElementById(element('clear-filters'));
  const cards = document.querySelectorAll(`.${element('card')}`);
  const rows = document.querySelectorAll(`.${element('table-body')} tr`);

  /**
   * Sets or clears a filter on the table of real estate items.
   * @param {boolean} clear Whether or not to clear the filter.
   */
  const filter = (clear = false) => {
    let value;
    if (!clear) {
      value = locationsFilter?.value;
    }
    const showClearButtonClass = element('clear-filters--visible');
    if (!value) {
      locationsFilter?.clear();
      clearButton?.classList.remove(showClearButtonClass);
    } else {
      clearButton?.classList.add(showClearButtonClass);
    }

    rows.forEach(r => {
      const airport = r.getAttribute('data-airport');
      const hideRowClass = element('hiddenRow');
      if (value === undefined || value?.length === 0 || value?.includes(airport)) {
        r.classList.remove(hideRowClass);
      } else {
        r.classList.add(hideRowClass);
      }
    });
  };

  // Clicking on a featured card will take you to that location.
  cards.forEach(c => {
      c.addEventListener('click', () => {
          let destination = `/locations/${c.dataset.airport}`;
          if (JSON.parse(c.dataset.isarticled.toLowerCase())) {
              destination = `/featured-real-estate?id=${c.dataset.id}`;
          }
          window.location.href = destination;
      });
  });

  // Clicking on a real estate table row will take you to that location.
  rows.forEach(r => {
    r.addEventListener('click', () => (window.location.href = `/locations/${r.dataset.airport}`));
  });

  locationsFilter?.addEventListener('on-change', () => filter());
  locationsFilter?.addEventListener('on-clear', () => filter(true));
  clearButton?.addEventListener('click', () => filter(true));
};

export default realEstateView;
