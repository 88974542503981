import bemify from '../../utils/bemUtils';
import Component from '../component/component';

/**
 * Represents a pagination component.
 */
class Pagination extends Component {
  #templateRef = null;
  #movePrevRef = null;
  #moveNextRef = null;
  #pagesRef = null;

  get currentPage() {
    return parseInt(this.getAttribute('current-page'));
  }

  set currentPage(value) {
    this.setAttribute('current-page', value);
  }

  get totalPages() {
    return parseInt(this.getAttribute('total-pages'));
  }

  set totalPages(value) {
    this.setAttribute('total-pages', value);
  }

  initTemplate() {
    const [block, element] = bemify('pagination');

    this.#templateRef = document.createElement('template');
    this.#templateRef.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <div class="${block()}">
        <button
          class="${element('move')}"
          type="button">
          <span class="material-symbols-outlined">chevron_left</span>
        </button>
        <ul class="${element('pages')}"></ul>
        <button
          class="${element('move')}"
          type="button">
          <span class="material-symbols-outlined">chevron_right</span>
        </button>
      </div>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.#templateRef.content.cloneNode(true));
  }

  /**
   * Called once to return the attributes to observe.
   */
  static get observedAttributes() {
    return ['currentPage', 'totalPages'];
  }

  onMounted() {
    this.#pagesRef = this.shadowRoot.querySelector('.pagination__pages');

    const moveButtons = this.shadowRoot.querySelectorAll('.pagination__move');

    this.#movePrevRef = moveButtons[0];

    if (this.#movePrevRef) {
      this.#movePrevRef.addEventListener('click', () => this.#onChange(this.currentPage - 1));
    }

    this.#moveNextRef = moveButtons[1];

    if (this.#moveNextRef) {
      this.#moveNextRef.addEventListener('click', () => this.#onChange(this.currentPage + 1));
    }
  }

  render() {
    this.#initPages();
  }

  #initPages() {
    const delta = this.currentPage > 2 && this.currentPage < this.totalPages - 1 ? 2 : 2;
    let rangeStart = Math.round(this.currentPage - delta / 2);
    let rangeEnd = Math.round(this.currentPage + delta / 2);

    if (rangeStart - 1 === 1 || rangeEnd + 1 === this.totalPages) {
      rangeStart += 1;
      rangeEnd += 1;
    }

    const _getRange = (start, end) => {
      return new Array(end - start + 1).fill().map((_, index) => index + start);
    };

    const _withEllipses = (value, pair) => {
      return updatedPages.length + 1 !== this.totalPages ? pair : [value];
    };

    let updatedPages = [];

    if (this.currentPage > delta) {
      updatedPages = _getRange(Math.min(rangeStart, this.totalPages - delta), Math.min(rangeEnd, this.totalPages));
    } else {
      updatedPages = _getRange(1, Math.min(this.totalPages, delta + 1));
    }

    if (updatedPages[0] !== 1) {
      updatedPages = _withEllipses(1, [1, '...']).concat(updatedPages);
    }

    if (updatedPages[updatedPages.length - 1] < this.totalPages) {
      updatedPages = updatedPages.concat(_withEllipses(this.totalPages, ['...', this.totalPages]));
    }

    let prevValue = 0;
    const pageItems = [];

    for (let index = 0; index < updatedPages.length; ++index) {
      const value = updatedPages[index];
      const page = {
        page: 0,
        value: ''
      };

      if (value !== '...') {
        prevValue = value;
        page.page = value;
      } else {
        page.page = prevValue + 1;
      }

      page.value = value;

      pageItems.push(page);
    }

    if (this.#pagesRef) {
      const [_, element] = bemify('pagination');
      const buttonClass = element('page');

      this.#pagesRef.innerHTML = '';

      for (let index = 0; index < pageItems.length; ++index) {
        const pageItem = pageItems[index];
        const button = document.createElement('button');
        button.classList.add(buttonClass);
        button.addEventListener('click', () => this.#onChange(pageItem.page));

        if (pageItem.page === this.currentPage) {
          button.classList.add(`${buttonClass}--active`);
        }

        button.textContent = pageItem.value;

        const li = document.createElement('li');
        li.appendChild(button);

        this.#pagesRef.appendChild(li);
      }
    }
  }

  #onChange(page) {
    const event = new CustomEvent('on-change', { detail: page });

    this.dispatchEvent(event);
  }
}

customElements.define('sk-pagination', Pagination);

export default Pagination;
