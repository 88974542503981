/**
 * Smoothly scrolls to the provided element.
 * @param {HTMLElement} element The element to scroll into view.
 * @param {Number} offsetY The vertical offset from the top of the element.
 * @returns
 */
export function scrollIntoView(element, offsetY = 0) {
  if (!element) {
    return;
  }

  const { top } = element.getBoundingClientRect();
  const scrollTop = window.scrollY || document.documentElement.scrollTop;
  const finalOffsetY = top + scrollTop - offsetY;

  window.scrollTo({
    top: finalOffsetY,
    behavior: 'smooth'
  });
}

/**
 * Scrolls the page to the top.
 */
export function scrollToTopOfDocument() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  });
}

/**
 * Scrolls the provided element to the top of its parent.
 * @param {HTMLElement} element The HTML element to scroll.
 */
export function scrollToTopOfParent(element) {
  if (element) {
    element.parentElement.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}

/**
 * Sets the document body and html element overflow to hidden to prevent scrolling.
 */
export function setDocumentOverflowToHidden() {
  document.body.style.overflow = 'hidden';
  document.documentElement.style.overflow = 'hidden';
}

/**
 * Sets the document body and html element overflow back to its initial value to allow scrolling.
 */
export function setDocumentOverflowToInitial() {
  document.body.style.overflow = '';
  document.documentElement.style.overflow = '';
}
