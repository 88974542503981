﻿import bemify from '../../utils/bemUtils';
import Component from '../component/component';
import Rest from '../../rest';

class AwardsCalculator extends Component {
    #makeModelsDropdownRef = null;
    #doubleAwardsRef = null;
    #bonusAwardsRef = null;
    #errorRef = null;
    #calcualteButtonRef = null;
    #waveLoaderRef = null;

    get title() {
        return this.getAttribute('title') ?? '';
    }
    get subtitle() {
        return this.getAttribute('subtitle') ?? '';
    }

    initTemplate() {
        const [block, element] = bemify('awards-calculator');
        const template = document.createElement('template');

        template.innerHTML = `
        <style>
            @import url('${process.env.APP_CSS_PATH}');
        </style>

        <sk-wave-loader id="awards-calculator__wave-loader"></sk-wave-loader>

        <div class="${block()}">
            ${this.#generateText()}

            <div class="${element('controls')}">
                <div class="form__group">
                    <sk-autocomplete id="awards-calculator__make-model"
                                     placeholder="Make & Model">
                    </sk-autocomplete>

                    <span class="form__required-message">Make & Model is required.</span>
                </div>

                <button id="awards-calculator__calculate-button" class="form__submit" type="button">
                    Calculate
                </button>

                <div id="awards-calculator__double-result" class="${element('result')}">
                </div>

                <div id="awards-calculator__bonus-result" class="${element('result')}">
                </div>

                <div id="awards-calculator__error" class="${element('error')}">
                    Awards information not available for selected make and model.
                </div>
            </div>
        </div>
        `;

        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    #loadMakeModels = async () => {
        try {
            this.#waveLoaderRef.show = true;

            await Rest.get(`${process.env.API_MAKE_MODELS}/GetAll`).then(async data => {
                if (!data) return;

                const makeModels = await data.text();
                this.#makeModelsDropdownRef.items = makeModels;
            });
        } catch (error) {
            console.error(error);
        } finally {
            this.#waveLoaderRef.show = false;
        }
    };

    #clearFieldErrors(field) {
        const requiredMessage = field.parentNode.querySelector('.form__required-message--visible');
        const invalidMessage = field.parentNode.querySelector('.form__invalid-message--visible');

        if (requiredMessage) requiredMessage.classList.remove('form__required-message--visible');
        if (invalidMessage) invalidMessage.classList.remove('form__invalid-message--visible');
    };

    #clearErrors() {
        const requiredMessages = document.querySelectorAll('.form__required-message');

        for (let index = 0; index < requiredMessages?.length; ++index) {
            const requiredMessage = requiredMessages[index];

            if (requiredMessage) {
                requiredMessage.classList.remove('form__required-message--visible');
            }
        }

        const invalidMessages = document.querySelectorAll('.form__invalid-message');

        for (let index = 0; index < invalidMessages?.length; ++index) {
            const invalidMessage = invalidMessages[index];

            if (invalidMessage) {
                invalidMessage.classList.remove('form__invalid-message--visible');
            }
        }
    };

    #showErrorMessage(field, group, errorClass, errorClassVisible) {
        const errorMessage = group?.querySelector(`.${errorClass}`);

        if (errorMessage) {
            field.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'start'
            });
            field.focus();
            errorMessage.classList.add(errorClassVisible);
        }
    };

    #validate() {
        this.#clearErrors();

        let hasErrors = false;

        if (this.#makeModelsDropdownRef.value?.length <= 0 || this.#makeModelsDropdownRef.value === 'undefined') {
            hasErrors = true;
            this.#showErrorMessage(
                this.#makeModelsDropdownRef,
                this.#makeModelsDropdownRef.parentElement,
                'form__required-message',
                'form__required-message--visible'
            );
        }

        return !hasErrors;
    };

    #calculate = async () => {
        try {
            const id = this.#makeModelsDropdownRef.value;

            if (!this.#validate()) {
                return;
            }

            this.#waveLoaderRef.show = true;

            await Rest.get(`/api/GetAwardsModifiers?makeModelId=${id}`).then(async data => {
                if (!data) return;

                const modifiers = await data.json();
                let hasModifiers = false;

                if (modifiers.DoubleAwardsMinGals) {
                    hasModifiers = true;

                    this.#doubleAwardsRef.textContent = `Gallons required for Double Awards: ${modifiers.DoubleAwardsMinGals}`;
                    this.#doubleAwardsRef.classList.add('awards-calculator__result--visible');
                } else {
                    this.#doubleAwardsRef.classList.remove('awards-calculator__result--visible');
                }

                if (modifiers.BonusAwardsMinGals && modifiers.BonusAwardsMinGals.length > 0 && modifiers.BonusAwardsMinGals[0].MinGallons) {
                    hasModifiers = true;

                    this.#bonusAwardsRef.textContent = `Gallons required for Bonus Awards: ${modifiers.BonusAwardsMinGals[0].MinGallons}`;
                    this.#bonusAwardsRef.classList.add('awards-calculator__result--visible');
                } else {
                    this.#bonusAwardsRef.classList.remove('awards-calculator__result--visible');
                }

                if (hasModifiers) {
                    this.#errorRef.classList.remove('awards-calculator__error--visible');
                } else {
                    this.#errorRef.classList.add('awards-calculator__error--visible');
                }
            });
        } catch (error) {
            console.error(error);
        } finally {
            this.#waveLoaderRef.show = false;
        }
    };

    #clearResults = () => {
        this.#doubleAwardsRef.classList.remove('awards-calculator__result--visible');
        this.#bonusAwardsRef.classList.remove('awards-calculator__result--visible');
        this.#errorRef.classList.remove('awards-calculator__error--visible');
    };

    #generateText() {
        if (!this.title && !this.subtitle) return '';

        const [_, element] = bemify('awards-calculator');

        return `
            <div class="${element('row')}">
                <div class="${element('col')}">
                    ${this.title ? `<span class="${element('title')}">${this.title}</span>` : ''}
                    ${this.subtitle ? `<span>${this.subtitle}</span>` : ''}
                </div>
            </div>
        `;
    }

    onMounted() {
        this.#makeModelsDropdownRef = this.shadowRoot.getElementById('awards-calculator__make-model');
        this.#doubleAwardsRef = this.shadowRoot.getElementById('awards-calculator__double-result');
        this.#bonusAwardsRef = this.shadowRoot.getElementById('awards-calculator__bonus-result');
        this.#errorRef = this.shadowRoot.getElementById('awards-calculator__error');
        this.#calcualteButtonRef = this.shadowRoot.getElementById('awards-calculator__calculate-button');
        this.#waveLoaderRef = this.shadowRoot.getElementById('awards-calculator__wave-loader');

        this.#makeModelsDropdownRef.addEventListener('on-change', ({ detail }) => {
            this.#clearResults();

            this.#makeModelsDropdownRef.value = detail.value;
            if (!detail.value || !this.#makeModelsDropdownRef.items.includes(`"${detail.value}"`)) return;

            this.#clearFieldErrors(this.#makeModelsDropdownRef);
        });
        this.#calcualteButtonRef.addEventListener('click', async () => await this.#calculate());

        this.#loadMakeModels();
    }

    render() {
    }
}

customElements.define('sk-awards-calculator', AwardsCalculator);

export default AwardsCalculator;