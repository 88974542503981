import bemify from '../../utils/bemUtils';

/**
 * Represents a slide for the Carousel.
 */
class CarouselSlide extends HTMLElement {
  /**
   * Initializes a new instance of the CarouselItem class.
   */
  constructor() {
    super();

    const [, element] = bemify('carousel');
    const template = document.createElement('template');
    template.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <li class="${element('slide')}">
        <div class="${element('slide-content')}">
          <slot></slot>
        </div>
      </li>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(template.content.cloneNode(true));
  }

  /**
   * Called when the component has been added to the DOM.
   */
  connectedCallback() {
    this.style.minWidth = '100%';
  }
}

customElements.define('sk-carousel-slide', CarouselSlide);
export default CarouselSlide;
