import { KeyboardKeys } from '../../enums';
import bemify from '../../utils/bemUtils';
import Component from '../component/component';

/**
 * Represents a modal.
 */
class Modal extends Component {
  #modalRef = null;
  #overlayRef = null;
  #onMouseDownElementRef = null;

  /**
   * Indicates whether or not this modal can be closed upon clicking-off, or pressing the 'Escape' key.
   */
  get lock() {
    return this.hasAttribute('lock') ?? false;
  }

  /**
   * Returns a value that determines whether the component is visible.
   */
  get show() {
    return this.hasAttribute('show');
  }

  /**
   * Sets a value that determines whether the component is visible.
   */
  set show(value) {
    if (value) {
      this.setAttribute('show', '');
    } else {
      this.removeAttribute('show');
    }
  }

    set onClose(callback) {
        this.#onCloseCallback = callback;
    }

  /**
   * Called once to return the attributes to observe.
   */
  static get observedAttributes() {
    return ['show'];
  }

  initTemplate() {
    const [block] = bemify('modal');
    const template = document.createElement('template');
    template.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <sk-overlay ${this.show ? 'show' : ''}>
        <div class="${block([this.show ? 'visible' : ''])}">
          <slot></slot>
        </div>
      </sk-overlay>
    `;

    let shadowRoot = this.shadowRoot;

    if (!shadowRoot) {
      shadowRoot = this.attachShadow({ mode: 'open' });
    }

    shadowRoot.appendChild(template.content.cloneNode(true));
  }

  onMounted() {
    this.#modalRef = this.shadowRoot.querySelector('.modal');
    this.#overlayRef = this.shadowRoot.querySelector('sk-overlay');

    if (!this.lock) {
      // Close modal on click directly on overlay.
      this.#overlayRef.addEventListener('mousedown', e => {
        this.#onMouseDownElementRef = e.target;
      });
      this.#overlayRef.addEventListener('mouseup', e => {
        if (e.target === this.#onMouseDownElementRef && e.target.tagName.toLowerCase() === 'sk-overlay') {
          this.show = false;
        }
      });

      // Close modal on 'ESC' key.
      document.addEventListener('keydown', e => {
        if (e.key === KeyboardKeys.esc) {
          this.show = false;
        }
      });
    }

    if (this.hasAttribute('loader')) {
      this.#modalRef.classList.add('modal--loader');
    }
  }

  render() {
    if (this.#modalRef) {
      if (this.show) {
        this.#setOverlayShow(true);
        this.#modalRef.classList.add('modal--visible');
      } else {
        this.#onCloseCallback();
        this.#setOverlayShow(false);
        this.#modalRef.classList.remove('modal--visible');
      }
    }
    return;
  }

  #setOverlayShow(show) {
    if (this.#overlayRef) {
      this.#overlayRef.show = show;
    }
  }

  #onCloseCallback = () => { };
}

customElements.define('sk-modal', Modal);
export default Modal;
