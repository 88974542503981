import bemify from '../../utils/bemUtils';
import Component from '../component/component';
import { debounce } from '../../utils/debounceUtils';

class CompactCarousel extends Component {
    #currSlideIndex = 0;
    #slotRef = null;
    #rangeCurrRef = null;
    #rangeEndRef = null;
    #prevButton = null;
    #nextButton = null;
    #safeWindowResize = null;

    initTemplate() {
        const [block, element] = bemify('compact-carousel');
        const template = document.createElement('template');
        template.innerHTML = `
            <style>
            @import url('${process.env.APP_CSS_PATH}');
            </style>
            <div class="${block()}">
                <ul class="${element('slides') }">
                    <slot />
                </ul>
                <div class="${element('controls')}">
                    <div class="${element('arrows')}">
                        <button
                            class="${element('prev')}"
                            type="button">
                            <span class="material-symbols-outlined">chevron_left</span>
                        </button>
                        <div class="${element('range-text')}">
                            <span class="${element('range-curr')}">00</span>
                            <span class="${element('range-end')}">00</span>
                        </div>
                        <button
                            class="${element('next')}"
                            type="button">
                            <span class="material-symbols-outlined">chevron_right</span>
                        </button>
                    </div>
                </div>
            </div>
        `;

        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    onMounted() {
        this.#slotRef = this.shadowRoot.querySelector('slot');
        this.#rangeCurrRef = this.shadowRoot.querySelector('.compact-carousel__range-curr');
        this.#rangeEndRef = this.shadowRoot.querySelector('.compact-carousel__range-end');
        this.#prevButton = this.shadowRoot.querySelector('.compact-carousel__prev');
        this.#prevButton.addEventListener('click', () => this.#slideIntoView(this.#currSlideIndex - 1));
        this.#nextButton = this.shadowRoot.querySelector('.compact-carousel__next');
        this.#nextButton.addEventListener('click', () => this.#slideIntoView(this.#currSlideIndex + 1));
        this.#safeWindowResize = debounce(() => {
            this.#currSlideIndex = 0;
            this.#slideIntoView(this.#currSlideIndex);
        }, 250);

        window.addEventListener('resize', this.#safeWindowResize);

        this.#slideIntoView(this.#currSlideIndex);
    }

    onUnmounted() {
        this.#prevButton.removeEventListener('click', this.#slideIntoView);
        this.#nextButton.removeEventListener('click', this.#slideIntoView);

        window.removeEventListener('resize', this.#safeWindowResize);
    }

    render() {
        const numSlides = this.#slotRef.assignedElements().length;

        this.#rangeCurrRef.textContent = `${this.#currSlideIndex + 1}`;
        this.#rangeEndRef.textContent = `/${numSlides}`;
    }

    #slideIntoView(newIndex) {
        let updatedIndex = newIndex;
        const slotChildren = this.#slotRef.assignedElements();
        const numSlides = slotChildren.length;

        if (updatedIndex < 0) {
            updatedIndex = 0;
        } else if (updatedIndex >= numSlides) {
            updatedIndex = numSlides - 1;
        }

        this.#currSlideIndex = updatedIndex;

        const slideNode = slotChildren[updatedIndex];
        slideNode?.scrollIntoView({
            block: 'nearest',
            inline: 'start'
        });

        this.render();
    }
}

customElements.define('sk-compact-carousel', CompactCarousel);

export default CompactCarousel;
