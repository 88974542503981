import bemify from '../../utils/bemUtils';
import Component from '../component/component';

class AccordionTab extends Component {
    initTemplate() {
        const uuid = self.crypto.randomUUID();
        const [block, element] = bemify('accordion__tab');
        const template = document.createElement('template');
        template.innerHTML = `
            <style>
                @import url('${process.env.APP_CSS_PATH}');
            </style>
            <div class="${block()}">
                <input type="checkbox" id="${uuid}">
                <label for="${uuid}" class="${element('label')}">${this.label}</label>
                <div class="${element('content')}">
                    <div>
                        <slot />
                    </div>
                </div>
            </div>
        `;

        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    get label() {
        return this.getAttribute('label') ?? '';
    }
}

customElements.define('sk-accordion-tab', AccordionTab);
export default AccordionTab;
