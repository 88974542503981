/**
 * Represents a map marker utilizing Google Maps internally.
 */
class Marker {
  #marker = null;

  /**
   * Initializes a new instance of the Marker class.
   * @param {Object} map The map that this marker will be added to.
   * @param {Object} options The marker options.
   */
  constructor(map, options) {
    this.#initMarker(map, options);
  }

  /**
   * Gets the content that represents the marker.
   */
  get content() {
    return this.#marker.content;
  }

  /**
   * Sets the content that represents the marker.
   */
  set content(value) {
    this.#marker.content = value;
  }

  /**
   * Gets the underlying marker object.
   */
  get marker() {
    return this.#marker;
  }

  /**
   * Gets the latitude/longitude of the marker.
   */
  get position() {
    return this.#marker.position;
  }

  /**
   * Gets the z-index of the marker.
   */
  get zIndex() {
    return this.#marker.zIndex;
  }

  /**
   * Sets the z-index of the marker.
   */
  set zIndex(value) {
    this.#marker.zIndex = value;
  }

  /**
   * Adds an event listener to the marker.
   * @param {String} event The event name.
   * @param {Function} callback The callback function.
   */
  addEventListener(event, callback) {
    this.#marker?.addListener(event, callback);
  }

  /**
   * Sets the map to show the marker for.
   * @param {Object} map The map to show the marker for.
   */
  setMap(map) {
    this.#marker?.setMap(map?.map);
  }

  #initMarker(map, options) {
    try {
      const { AdvancedMarkerElement } = window.aa;
      this.#marker = new AdvancedMarkerElement({
        map: map?.map,
        ...options
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export default Marker;
