const articlesView = () => {
  if (document.querySelector('.articles')) {

    document.getElementById('news-categories').addEventListener('on-change', LoadCategories);

    function LoadCategories() {
        const selectedCategory = document.getElementById('news-categories').value;

        if (selectedCategory) {
            window.location.href = `/news?category=${encodeURIComponent(selectedCategory)}`;
        }
        else {
            window.location.href = '/news?category=All%20News';
        }
    }

    const clearFilter = document.querySelector('.articles__clear-filters');

    if (clearFilter) {
        const queryStrings = new URLSearchParams(location.search);

        const selectedCategory = queryStrings.get('category');

        if (!selectedCategory) {
            location.search = '?category=All%20News';
        }

        if (selectedCategory != 'All News') {
            clearFilter.classList.add('advisories__clear-filters--visible');
        } else {
            clearFilter.classList.remove('advisories__clear-filters--visible');
        }

        clearFilter.addEventListener('click', () => {
            location.search = '?category=All%20News';
        });
    }

    const pagination = document.getElementById('pagination');

    if (pagination) {
      pagination.addEventListener('on-change', ({ detail: page }) => {
        const queryStrings = new URLSearchParams(location.search);
        let configTake = queryStrings.get('take');

        if (!configTake) {
          configTake = parseInt(process.env.APP_NEWS_ARTICLES_TAKE);
        } else {
          configTake = parseInt(configTake);
        }

        let gatedPage = page;

        if (gatedPage > pagination.totalPages) {
          gatedPage = gatedPage - 1;
        } else if (gatedPage <= 0) {
          gatedPage = 1;
        }

        const skip = (gatedPage - 1) * configTake;

        queryStrings.set('page', gatedPage);
        queryStrings.set('skip', skip);
        queryStrings.set('take', configTake);

        location.search = queryStrings.toString();
      });
    }
  }
};

export default articlesView;
