// Define an empty property to represent an empty string vs. comparing to ''.
if (!String.empty) {
  Object.defineProperty(String, 'empty', {
    value: '',
    writable: false
  });
}

// Define a static method for checking if a provided string is null or empty.
if (!String.isNullOrEmpty) {
  String.isNullOrEmpty = function isNullOrEmpty(value) {
    return !value || !value.trim();
  };
}
