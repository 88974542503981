import { MapConstants } from '../constants';
import { poll } from '../utils/debounceUtils';
import Marker from '../components/map/marker';
import InfoWindow from '../components/map/infoWindow';
import LocationsService from '../services/locationsService';
import { scrollIntoView } from '../utils/domUtils';

const locationsView = () => {
  if (document.querySelector('.locations')) {
    const map = document.querySelector('#locations-map');
    const mapSearch = document.querySelector('#map-search');
    const dirSearch = document.querySelector('#dir-search');
    const locationsList = document.querySelector('.locations__list');
    const locationsFilters = document.querySelector('#filter-by');
    const locationsDirectory = document.querySelector('.locations__directory');
    const locationsMapWrapper = document.querySelector('.locations__map-wrapper');

    if (
      map &&
      mapSearch &&
      dirSearch &&
      locationsList &&
      locationsFilters &&
      locationsDirectory &&
      locationsMapWrapper
    ) {
      const markers = [];
      let locations = [];
      let locationItems = null;
      let previousFocusedMarker = null;

      const initFilterByList = () => {
        const filters = [
          {
            label: 'Show all',
            value: 'Show all'
          },
          {
            label: 'New Location',
            value: 'New Location'
          },
          {
            label: 'Coming Soon Location',
            value: 'Coming Soon Location'
          },
          {
            label: 'Real Estate Availability',
            value: 'Real Estate Availability'
          },
          {
            label: 'Flightcraft Maintenance',
            value: 'Flightcraft Maintenance'
          }
        ];

        locationsFilters.items = JSON.stringify(filters);
        locationsFilters.addEventListener('on-change', ({ detail }) => filterMapLocations('', '', detail?.value));
      };

      const initMarkers = () => {
        for (let index = 0; index < locations?.length; ++index) {
          const location = locations[index];
          const { airportCode, latitude, longitude } = location;
          const markerPin = document.createElement('span');
          markerPin.textContent = airportCode;
          markerPin.className = 'marker';

          const marker = new Marker(map, {
            content: markerPin,
            gmpClickable: true,
            position: { lat: latitude, lng: longitude }
          });
          marker.location = location;
          marker.addEventListener('click', () => showMarkerInfo(map, marker, location));

          markers.push(marker);
        }
      };

      const initLocationSearch = () => {
        const items = [];
        dirSearch.displayedValue = '';

        for (let index = 0; index < locations?.length; ++index) {
          const location = locations[index];
          const { airportCode, city, state } = location;
          const item = {
            label: `${airportCode} - ${city}, ${state}`,
            value: airportCode
          };

          items.push(item);
        }

        mapSearch.items = JSON.stringify(items);
        dirSearch.items = JSON.stringify(items);
        mapSearch.addEventListener('on-change', ({ detail }) => {
          const { value: airportCode, reason } = detail;

          filterMapLocations(airportCode, reason);
        });
        dirSearch.addEventListener('on-change', ({ detail }) => {
          const { value: airportCode, reason } = detail;

          filterLocationsList(airportCode, reason);
        });
      };

      const initLocationsList = () => {
        locationsList.innerHTML = '';
        for (let index = 0; index < locations?.length; ++index) {
          const location = locations[index];
          const { airportCode, city, state } = location;
          const item = document.createElement('li');
          item.classList.add('locations__item');
          item.setAttribute('data-airport-code', airportCode);
          item.innerHTML = `
            <a
              class="locations__link"
              href="/locations/${airportCode}">
              ${airportCode} - ${city}, ${state}
            </a>
          `;

          locationsList.appendChild(item);
        }

        locationItems = locationsList.querySelectorAll('.locations__item');
      };

      const getLocations = async () => {
        const locationsService = new LocationsService();
        locations = await locationsService.getLocations();

        initFilterByList();
        initLocationSearch();
        initLocationsList();
      };
      getLocations();

      let infoWindow = null;
      const showMarkerInfo = (map, marker, location) => {
        if (!infoWindow) {
          infoWindow = new InfoWindow();
        }

        const { airportCode, city, state, phone, hoursOfOperation } = location;
        const content = document.createElement('div');
        content.classList.add('info-window');
        content.innerHTML = `
          <h3 class="info-window__title">Atlantic Aviation ${airportCode}</h3>
          <h4 class="info-window__city-state">${city}, ${state}</h4>
          <a
            class="info-window__phone"
            href="tel:${phone}">
            ${phone}
          </a>
          <p class="info-window__hours">${hoursOfOperation}</p>
          <a
            class="info-window__view-location"
            href="/locations/${airportCode}">
            View FBO
          </a>
        `;

        infoWindow.close();
        infoWindow.setContent(content);
        infoWindow.open(map, marker);
      };

      const filterMapLocations = (airportCode, filterReason, filterType) => {
        let markerToPanTo = null;

        if (airportCode || filterType) {
          const filter = airportCode?.toLowerCase();

          for (const marker of markers) {
            marker.setMap(map);

            if (
              airportCode &&
              filterReason === 'itemSelected' &&
              marker.location.airportCode.toLowerCase() === filter
            ) {
              markerToPanTo = marker;
              previousFocusedMarker = markerToPanTo;
              break;
            } else if (filterType) {
              let propToCheck = '';

              if (filterType === 'New Location') {
                propToCheck = 'isNewLocation';
              } else if (filterType === 'Coming Soon Location') {
                propToCheck = 'isComingSoonLocation';
              } else if (filterType === 'Real Estate Availability') {
                propToCheck = 'hasRealEstateAvailability';
              } else if (filterType === 'Flightcraft Maintenance') {
                propToCheck = 'hasFlightcraftMaintenance';
              }

              if (propToCheck && !marker.location[propToCheck]) {
                marker.setMap(null);
              }
            }
          }
        }

        if (markerToPanTo) {
          markerToPanTo.zIndex = 999;

          const markerFocused = markerToPanTo.content;
          markerFocused.classList.add('marker--focused');

          locationsFilters.reset();
          map.panTo(markerToPanTo.position);
          map.zoom = MapConstants.markerZoom;
        } else if (!markerToPanTo && airportCode === '' && !filterType) {
          if (previousFocusedMarker) {
            previousFocusedMarker.zIndex = 0;

            const marker = previousFocusedMarker.content;
            marker.classList.remove('marker--focused');
          }

          map.zoom = MapConstants.defaultZoom;
          map.panTo(MapConstants.usCenter);
        }
      };

      const filterLocationsList = (airportCode, reason) => {
        const filter = airportCode?.toLowerCase();

        for (let index = 0; index < locationItems?.length; ++index) {
          const locationItem = locationItems[index];
          locationItem.classList.add('locations__item--hidden');

          if (
            reason === 'cleared' ||
            (reason !== 'input' &&
              locationItem.getAttribute('data-airport-code').toLowerCase() === filter.toLowerCase()) ||
            (reason === 'input' &&
              new RegExp(filter.replace('+', '\\+').toLowerCase()).test(locationItem.innerText.toLowerCase()))
          ) {
            locationItem.classList.remove('locations__item--hidden');
          }
        }
      };

      mapSearch.addEventListener('on-focus', () => scrollIntoView(locationsMapWrapper, 85));

      const fiveSeconds = 5000;
      const tenthOfASecond = 100;

      // Let's poll for a bit until we have a map and our locations have been loaded.
      poll(
        () => {
          if (window?.aa?.Map && locations?.length > 0) {
            return true;
          }
        },
        fiveSeconds,
        tenthOfASecond
      )
        .then(() => {
          initMarkers();
        })
        .catch(error => console.error(error));
    }
  }
};

export default locationsView;
