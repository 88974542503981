import { KeyboardKeys } from '../../enums';
import bemify from '../../utils/bemUtils';
import { setDocumentOverflowToHidden, setDocumentOverflowToInitial } from '../../utils/domUtils';
import DrawerAnchor from './drawerAnchor';

/**
 * Represents content that slides in from the sides of the screen.
 */
class Drawer extends HTMLElement {
  #drawerRef = null;
  #onMouseDownElementRef = null;
  #overlayRef = null;
  #prevAnchor = '';

  /**
   * Indicates whether or not this modal can be closed upon clicking-off, or pressing the 'Escape' key.
   */
  get lock() {
    return this.hasAttribute('lock') ?? false;
  }

  /**
   * Initializes a new instance of the Drawer class.
   */
  constructor() {
    super();

    this.#prevAnchor = this.anchor;

    const [block] = bemify('drawer');
    const template = document.createElement('template');
    template.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
    `;

    if (this.useOverlay) {
      template.innerHTML += `
        <sk-overlay ${this.show ? 'show' : ''}>
          <div class="${block([`anchor-${this.anchor}`, this.show ? 'visible' : ''])}">
            <slot></slot>
          </div>
        </sk-overlay>
      `;
    } else {
      template.innerHTML += `
        <div class="${block([`anchor-${this.anchor}`, this.show ? 'visible' : ''])}">
          <slot></slot>
        </div>
      `;
    }

    let shadowRoot = this.shadowRoot;

    if (!shadowRoot) {
      shadowRoot = this.attachShadow({ mode: 'open' });
    }

    shadowRoot.appendChild(template.content.cloneNode(true));
  }

  /**
   * Gets the anchor position of the component.
   */
  get anchor() {
    let anchorAttr = this.getAttribute('anchor');

    if (!anchorAttr) {
      anchorAttr = DrawerAnchor.right;
    }

    return anchorAttr;
  }

  /**
   * Sets the anchor position of the component.
   */
  set anchor(value) {
    if (value !== this.#prevAnchor) {
      this.setAttribute('anchor', value);
      this.#drawerRef.classList.remove(`drawer--anchor-${this.#prevAnchor}`);
      this.#drawerRef.classList.add(`drawer--anchor-${value}`);

      this.#prevAnchor = value;
    }
  }

  /**
   * Returns a value that determines whether the component is visible.
   */
  get show() {
    return this.hasAttribute('show');
  }

  /**
   * Sets a value that determines whether the component is visible.
   */
  set show(value) {
    if (value) {
      this.setAttribute('show', '');
      setDocumentOverflowToHidden();
    } else {
      this.removeAttribute('show');
      setDocumentOverflowToInitial();
    }
  }

  /**
   * Gets a value that determines whether to use an overlay.
   */
  get useOverlay() {
    return !this.hasAttribute('use-overlay');
  }

  /**
   * Sets a value that determines whether to use an overlay.
   */
  set useOverlay(value) {
    if (value) {
      this.setAttribute('use-overlay', '');
    } else {
      this.removeAttribute('use-overlay');
    }
  }

  /**
   * Called every time an observed attribute changes.
   * @param {String} property The property that changed.
   * @param {String} oldValue The old value.
   * @param {String} newValue The new value.
   */
  attributeChangedCallback(property, oldValue, newValue) {
    if (oldValue !== newValue) {
      this.render();
    }
  }

  /**
   * Called when the component has been added to the DOM.
   */
  connectedCallback() {
    this.#drawerRef = this.shadowRoot.querySelector('.drawer');
    this.#overlayRef = this.shadowRoot.querySelector('sk-overlay');

    if (!this.lock) {
      // Close modal on click directly on overlay.
      this.#overlayRef.addEventListener('mousedown', e => {
        this.#onMouseDownElementRef = e.target;
      });
      this.#overlayRef.addEventListener('mouseup', e => {
        if (e.target === this.#onMouseDownElementRef && e.target.tagName.toLowerCase() === 'sk-overlay') {
          this.show = false;
        }
      });

      // Close modal on 'ESC' key.
      document.addEventListener('keydown', e => {
        if (e.key === KeyboardKeys.esc) {
          this.show = false;
        }
      });
    }

    this.render();
  }

  /**
   * Called once to return the attributes to observe.
   */
  static get observedAttributes() {
    return ['anchor', 'show'];
  }

  #setOverlayShow(show) {
    if (this.#overlayRef) {
      this.#overlayRef.show = show;
    }
  }

  /**
   * Called when it's time to render the component.
   */
  render() {
    if (this.#drawerRef) {
      if (this.show) {
        this.#setOverlayShow(true);
        this.#drawerRef.classList.add('drawer--visible');
      } else {
        this.#setOverlayShow(false);
        this.#drawerRef.classList.remove('drawer--visible');
      }
    }
  }
}

customElements.define('sk-drawer', Drawer);
export default Drawer;
