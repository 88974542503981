import Component from '../component/component';
import bemify from '../../utils/bemUtils';
import Overlay from '../overlay/overlay';

/**
 * Represents a wave loader animation.
 */
class WaveLoader extends Component {
  #loaderRef = null;
  #overlayRef = null;

  /**
   * Returns a value that determines whether the component is visible.
   */
  get show() {
    return this.hasAttribute('show');
  }

  /**
   * Sets a value that determines whether the component is visible.
   */
  set show(value) {
    if (value) {
      this.setAttribute('show', '');
    } else {
      this.removeAttribute('show');
    }
  }

  initTemplate() {
    const [block, element] = bemify('wave-loader');

    this.#overlayRef = new Overlay();
    this.#overlayRef.show = this.show;
    this.#overlayRef.innerHTML = `
      <style>
        @import url('${process.env.APP_CSS_PATH}');
      </style>
      <div class="${block()}">
        <div class="${element('wave')}"></div>
        <div class="${element('wave')}"></div>
        <div class="${element('wave')}"></div>
        <div class="${element('wave')}"></div>
        <div class="${element('wave')}"></div>
      </div>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.#overlayRef);
  }

  /**
   * Called once to return the attributes to observe.
   */
  static get observedAttributes() {
    return ['show'];
  }

  onMounted() {
    this.#loaderRef = this.shadowRoot.querySelector('.wave-loader');
  }

  render() {
    if (this.#loaderRef) {
      if (this.show) {
        this.#setOverlayShow(true);
      } else {
        this.#setOverlayShow(false);
      }
    }
  }

  #setOverlayShow(show) {
    if (this.#overlayRef) {
      this.#overlayRef.show = show;
    }
  }
}

customElements.define('sk-wave-loader', WaveLoader);

export default WaveLoader;
