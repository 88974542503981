import { KeyboardKeys } from '../../../enums';
import bemify from '../../../utils/bemUtils';
import Component from '../../component/component';

class AutocompleteChecklist extends Component {
  #templateRef = null;
  #dropdownRef = null;
  #dropdownLabelRef = null;
  #dropdownItemsRef = null;
  #autocompleteInputRef = null;
  #arrowRef = null;
  #currActiveIndex = -1;
  #label = '';
  #selectedItems = [];

  get items() {
    return this.getAttribute('items');
  }

  set items(value) {
    this.setAttribute('items', value);
  }

  get placeholder() {
    return this.getAttribute('placeholder');
  }

  set placeholder(value) {
    this.setAttribute('placeholder', value);
  }

  get open() {
    return this.hasAttribute('open');
  }

  get inputId() {
    return this.getAttribute('inputId') ?? '';
  }

  get inputName() {
    return this.getAttribute('inputName') ?? '';
  }

  set open(value) {
    if (value) {
      this.setAttribute('open', '');
    } else {
      this.removeAttribute('open');
    }
  }

  get value() {
    return this.#selectedItems;
  }

  get chipText() {
    return this.getAttribute('chiptext');
  }

  set chipText(value) {
    this.setAttribute('chiptext', value);
  }

  get selectedOnTop() {
    return this.hasAttribute('selectedontop');
  }

  set selectedOnTop(value) {
    if (value) {
      this.setAttribute('selectedontop', '');
    } else {
      this.removeAttribute('selectedontop');
    }
  }

  get selectAll() {
    return this.hasAttribute('selectall');
  }

  set selectAll(value) {
    if (value) {
      this.setAttribute('selectall', '');
    } else {
      this.removeAttribute('selectall');
    }
  }

  get selectAllText() {
    return this.getAttribute('selectalltext') || 'Select All';
  }

  set selectAllText(value) {
    this.setAttribute('selectalltext', value);
  }

  get disabled() {
    return this.hasAttribute('disabled');
  }

  set disabled(value) {
    if (value) {
      this.setAttribute('disabled', '');
    } else {
      this.removeAttribute('disabled');
    }
  }

  initTemplate() {
    const [block, element] = bemify('autocomplete-checklist');

    this.#templateRef = document.createElement('template');
    this.#templateRef.innerHTML = `
        <style>
            @import url('${process.env.APP_CSS_PATH}');
        </style>
        <div class="${block()}">
            <input
                class="${element('dropdown')} ${element('input')}"
                type="text"
                placeholder="${this.placeholder}" />
            <span class="${element('label')}">${this.#label}</span>
            <span class="material-symbols-outlined ${element('icon')}">keyboard_arrow_down</span>
            <ul class="${element('items')}"></ul>
        </div>
    `;

    const shadowRoot = this.attachShadow({ mode: 'open' });
    shadowRoot.appendChild(this.#templateRef.content.cloneNode(true));
  }

  onMounted() {
    this.#autocompleteInputRef = this.shadowRoot.querySelector('.autocomplete-checklist__input');

    if (this.#autocompleteInputRef) {
      this.#autocompleteInputRef.addEventListener('input', e => this.#onInput(e.target.value));
      this.#autocompleteInputRef.addEventListener('keydown', e => this.#onKeyDown(e));

      if (this.inputId) {
        this.#autocompleteInputRef.id = this.inputId;
      }
      if (this.inputName) {
        this.#autocompleteInputRef.name = this.inputName;
      }

      if (this.hasAttribute('required')) {
        this.#autocompleteInputRef.setAttribute('required', '');
      }

      this.disabled = this.hasAttribute('disabled');
      this.readonly = this.hasAttribute('readonly');
    }

    // Close on click-away.
    document.addEventListener('click', e => {
      const target = e.composedPath()[0];
      if (
        target !== this &&
        !this.shadowRoot.contains(target) &&
        !this.shadowRoot.contains(target.getRootNode().host)
      ) {
        this.#onToggleDropdown(false);
      }
    });

    // Close on 'ESC' key.
    document.addEventListener('keydown', e => {
      if (e.key === KeyboardKeys.esc) {
        this.#onToggleDropdown(false);
      }
    });

    this.#dropdownRef = this.shadowRoot.querySelector('.autocomplete-checklist__dropdown');

    if (this.#dropdownRef) {
      this.#dropdownRef.addEventListener('click', () => {
        if (this.disabled) {
          return;
        }

        this.#onToggleDropdown();
      });
    }

    this.#dropdownLabelRef = this.shadowRoot.querySelector('.autocomplete-checklist__label');

    if (this.#dropdownLabelRef) {
      this.#dropdownLabelRef.addEventListener('click', e => this.#onLabelClick(e));
    }

    this.#dropdownItemsRef = this.shadowRoot.querySelector('.autocomplete-checklist__items');

    if (this.#dropdownItemsRef) {
      this.#loadItems();
    }

    this.#arrowRef = this.shadowRoot.querySelector('.autocomplete-checklist__icon');

    if (this.#arrowRef && this.#autocompleteInputRef) {
      this.#arrowRef.addEventListener('click', () => {
        if (this.disabled) {
          return;
        }

        this.#onToggleDropdown();
        this.#autocompleteInputRef.focus();
      });
    }
  }

  static get observedAttributes() {
    return ['items', 'open', 'disabled'];
  }

  render() {
    if (this.open) {
      this.#dropdownRef?.classList.add('autocomplete-checklist__dropdown--opened');
      this.#dropdownItemsRef?.classList.add('autocomplete-checklist__items--visible');
    } else {
      this.#dropdownRef?.classList.remove('autocomplete-checklist__dropdown--opened');
      this.#dropdownItemsRef?.classList.remove('autocomplete-checklist__items--visible');
    }

    if (this.disabled) {
      this.#autocompleteInputRef?.setAttribute('disabled', '');
      this.#dropdownRef?.setAttribute('disabled', '');
    } else {
      this.#autocompleteInputRef?.removeAttribute('disabled');
      this.#dropdownRef?.removeAttribute('disabled');
    }

    return;
  }

  checkAll() {
    if (this.disabled) {
      return;
    }

    const items = JSON.parse(this.items);

    for (let index = 0; index < items?.length; ++index) {
      const item = items[index];
      const element = this.shadowRoot.getElementById(item.value);

      if (!element) {
        continue;
      }

      const checkbox = element.shadowRoot.querySelector('.checkbox__checkbox');

      if (checkbox.checked) {
        continue;
      }

      checkbox.checked = true;
      this.#onItemChange(true, item.value, false);
    }

    const event = new CustomEvent('on-select-all');
    this.dispatchEvent(event);
  }

  clear() {
    if (this.disabled) {
      return;
    }

    let items = JSON.parse(this.items);

    if (this.selectAll) {
      const selectAll = [{ label: this.selectAllText, value: 'selectAll' }];

      items = selectAll.concat(items);
    }

    for (let index = 0; index < items?.length; ++index) {
      const item = items[index];
      const element = this.shadowRoot.getElementById(item.value);

      if (!element) {
        continue;
      }

      const checkbox = element.shadowRoot.querySelector('.checkbox__checkbox');

      if (!checkbox.checked) {
        continue;
      }

      checkbox.checked = false;
      this.#onItemChange(false, item.value, false);
    }

    const event = new CustomEvent('on-clear');
    this.dispatchEvent(event);
  }

  #adjustItemsPosition() {
    const dropdownBounds = this.#dropdownRef.getBoundingClientRect();
    const dropdownItemsBounds = this.#dropdownItemsRef.getBoundingClientRect();

    if (dropdownBounds.bottom + dropdownItemsBounds.height >= document.documentElement.clientHeight) {
      this.#dropdownItemsRef.style.bottom = '105%';
      this.#dropdownItemsRef.style.top = 'unset';
    } else {
      this.#dropdownItemsRef.style.top = '105%';
      this.#dropdownItemsRef.style.bottom = 'unset';
    }
  }

  #loadItems(items) {
    if (!items || items.length === 0) items = JSON.parse(this.items);

    if (this.selectedOnTop) {
      items = items
        .filter(item => item.isApplied || this.#selectedItems.includes(item.value))
        .concat(items.filter(item => !item.isApplied && !this.#selectedItems.includes(item.value)));
    }

    if (this.selectAll && this.#autocompleteInputRef.value === '') {
      const selectAll = [{ label: this.selectAllText, value: 'selectAll', isApplied: this.#areAllSelected(items) }];

      items = selectAll.concat(items);
    }

    this.#dropdownItemsRef.innerHTML = '';

    for (let index = 0; index < items?.length; ++index) {
      const item = items[index];
      const itemLabel = document.createElement('span');
      itemLabel.textContent = item.label;

      const filter = document.createElement('sk-checkbox');
      filter.id = item.value;
      filter.label = item.label;
      filter.defaultChecked = item.isApplied || this.#selectedItems.includes(item.value);
      filter.tabIndex = '-1';
      filter.setAttribute('fullwidth', '');

      filter.addEventListener('on-change', ({ detail: checked }) => {
        if (item.value === 'selectAll') {
          checked === true ? this.checkAll() : this.clear();
        } else {
          this.#onItemChange(checked, item.value, true);
        }
      });

      const hr = document.createElement('hr');
      hr.classList.add('autocomplete-checklist__delimiter');

      const li = document.createElement('li');
      li.classList.add('autocomplete-checklist__item');
      li.appendChild(filter);
      li.appendChild(hr);

      if (item.isApplied && !this.#selectedItems.includes(item.value)) {
        this.#selectedItems.push(item.value);
      }

      this.#updateLabel();

      this.#dropdownItemsRef.appendChild(li);
    }
  }

  #onLabelClick(e) {
    if (this.disabled) {
      return;
    }

    e.stopPropagation();

    const event = new CustomEvent('on-clear');

    this.dispatchEvent(event);
    this.clear();
  }

  #onItemChange(checked, value, dispatchEvent) {
    if (this.disabled) {
      return;
    }

    if (checked && !this.#selectedItems.includes(value)) {
      this.#selectedItems.push(value);
    } else {
      this.#selectedItems.splice(this.#selectedItems.indexOf(value), 1);
    }

    const selectAll = this.shadowRoot.getElementById('selectAll');

    if (selectAll) {
      const checkbox = selectAll.shadowRoot.querySelector('.checkbox__checkbox');
      checkbox.checked = this.#areAllSelected();
    }

    if (this.#autocompleteInputRef.value !== '') {
      this.#autocompleteInputRef.value = '';
      this.#loadItems();
    }

    this.#updateLabel();

    if (dispatchEvent) {
      const event = new CustomEvent('on-change', { detail: { checked: checked, value: value } });

      this.dispatchEvent(event);
    }
  }

  #onToggleDropdown(isOpening = !this.open) {
    if (isOpening === this.open) {
      return;
    }

    let event = null;
    if (isOpening) {
      event = new CustomEvent('on-before-open', { detail: this.#dropdownItemsRef });
    } else {
      event = new CustomEvent('on-before-close', { detail: this.#dropdownItemsRef });
    }

    this.dispatchEvent(event);
    this.open = isOpening;
    this.#loadItems();

    if (this.open) {
      this.#updateItemFocus();
      this.#adjustItemsPosition();
    } else {
      this.#currActiveIndex = -1;
      this.#autocompleteInputRef.value = '';
    }
  }

  #onInput(value) {
    if (!value) {
      this.open = false;
    }

    const escapedValue = value.replace('+', '\\+');
    const regex = new RegExp(escapedValue.toUpperCase());

    const exactMatchItems = JSON.parse(this.items).filter(item => {
      if (!item.label) {
        return item.toString().toUpperCase().startsWith(value.toUpperCase());
      }

      return (
        item.label.toString().toUpperCase().startsWith(value.toUpperCase()) ||
        item.value.toString().toUpperCase().startsWith(value.toUpperCase())
      );
    });

    const matchItems = JSON.parse(this.items).filter(item => {
      if (exactMatchItems.some(i => JSON.stringify(i) === JSON.stringify(item))) {
        return false;
      }

      if (!item.label) {
        return regex.test(item.toString().toUpperCase());
      }

      return regex.test(item.label.toString().toUpperCase()) || regex.test(item.value.toString().toUpperCase());
    });

    const items = exactMatchItems.concat(matchItems);

    this.#currActiveIndex = 0;
    this.#loadItems(items);
    this.#updateItemFocus();
    this.open = items.length > 0;
    this.#adjustItemsPosition();
  }

  #onKeyDown(e) {
    const { key } = e;
    let open = false;

    switch (key) {
      case KeyboardKeys.arrowDown:
        e.preventDefault();
        open = true;
        this.#currActiveIndex = Math.min(this.#currActiveIndex + 1, this.#dropdownItemsRef?.childNodes.length - 1);
        break;
      case KeyboardKeys.arrowUp:
        e.preventDefault();
        open = true;
        this.#currActiveIndex = Math.max(this.#currActiveIndex - 1, 0);
        break;
      case KeyboardKeys.enter: {
        e.preventDefault();
        if (this.#currActiveIndex >= 0) {
          const item = this.#dropdownItemsRef?.childNodes[this.#currActiveIndex];
          const element = item.querySelector('sk-checkbox');
          const checkbox = element.shadowRoot.querySelector('.checkbox__checkbox');

          checkbox.checked = !checkbox.checked;
          this.#onItemChange(checkbox.checked, element.id, true);
        }
        break;
      }
      case KeyboardKeys.tab: {
        this.#onToggleDropdown(false);
        break;
      }
    }

    if (open) {
      this.open = true;
      this.#updateItemFocus();
    }
  }

  #updateItemFocus() {
    let foundFocusedItem = null;

    for (let index = 0; index < this.#dropdownItemsRef?.childNodes.length; ++index) {
      const listItem = this.#dropdownItemsRef?.childNodes[index];

      if (index === this.#currActiveIndex) {
        listItem.classList.add('autocomplete-checklist__item--active');

        foundFocusedItem = listItem;
      } else {
        listItem.classList.remove('autocomplete-checklist__item--active');
      }
    }

    if (foundFocusedItem) {
      foundFocusedItem.focus();
      foundFocusedItem.scrollIntoView({ block: 'nearest' });
    }
  }

  #updateLabel() {
    const count = this.#selectedItems.filter(item => item !== 'selectAll').length;

    if (count > 0) {
      if (this.chipText) {
        this.#dropdownLabelRef.innerHTML = `${this.chipText} (${count})<span class="material-symbols-outlined">close</span>`;
      } else {
        this.#dropdownLabelRef.innerHTML = `${count}<span class="material-symbols-outlined">close</span>`;
      }

      this.#dropdownLabelRef.classList.add('autocomplete-checklist__label--visible');
    } else {
      this.#dropdownLabelRef.classList.remove('autocomplete-checklist__label--visible');
    }

    this.#autocompleteInputRef.setAttribute(
      'style',
      `padding-left: calc(0.675em + ${this.#dropdownLabelRef.offsetWidth}px)`
    );
    this.#autocompleteInputRef.setAttribute('placeholder', this.#selectedItems.length > 0 ? '' : this.placeholder);
  }

  #areAllSelected(items) {
    if (!items || items.length === 0) items = JSON.parse(this.items);

    return items.filter(item => !item.isApplied && !this.#selectedItems.includes(item.value)).length === 0;
  }
}

customElements.define('sk-autocomplete-checklist', AutocompleteChecklist);

export default AutocompleteChecklist;
