import bemify from '../../utils/bemUtils';
import Component from '../component/component';

class Comment extends Component {
    initTemplate() {
        const [block, element] = bemify('comment');
        const template = document.createElement('template');
        template.innerHTML = `
            <style>
                @import url('${process.env.APP_CSS_PATH}');
            </style>
            <div class="${block()}">
                <div class="${element('author')}">
                    <img class="${element('author__image')}" src="${this.image}" />
                    <div class="${element('author__info')}">
                        <div class="${element('author__info__label')}">${this.name}</div>
                        <div class="${element('author__info__title')}">${this.title}</div>
                    </div>
                </div>

                <div class="${element('comment')}">${this.comment}</div>
            </div>
        `;
        const shadowRoot = this.attachShadow({ mode: 'open' });
        shadowRoot.appendChild(template.content.cloneNode(true));
    }

    onMounted() {
    }

    get image() {
        return this.getAttribute('image') ?? '';
    }

    get name() {
        return this.getAttribute('name') ?? '';
    }

    get title() {
        return this.getAttribute('title') ?? '';
    }

    get comment() {
        return this.getAttribute('comment') ?? '';
    }

    render() {
    }
}

customElements.define('sk-comment', Comment);

export default Comment;
