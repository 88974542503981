const advisoriesView = () => {
  if (!document.querySelector('.advisories')) {
    return;
  }

  const clearErrors = () => {
    const requiredMessages = document.querySelectorAll('.form__required-message');

    for (let index = 0; index < requiredMessages?.length; ++index) {
      const requiredMessage = requiredMessages[index];

      if (requiredMessage) {
        requiredMessage.classList.remove('form__required-message--visible');
      }
    }

    const invalidMessages = document.querySelectorAll('.form__invalid-message');

    for (let index = 0; index < invalidMessages?.length; ++index) {
      const invalidMessage = invalidMessages[index];

      if (invalidMessage) {
        invalidMessage.classList.remove('form__invalid-message--visible');
      }
    }
  };

  const showErrorMessage = (field, group, errorClass, errorClassVisible) => {
    const errorMessage = group?.querySelector(`.${errorClass}`);

    if (errorMessage) {
      field.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'start'
      });
      field.focus();
      errorMessage.classList.add(errorClassVisible);
    }
  };

  const alertsSignUp = document.querySelector('.advisories__sign-up');

  if (alertsSignUp) {
    const signupModal = document.getElementById('sign-up-modal');
    const closeModal = document.querySelector('.advisories__modal-close');

    if (closeModal) {
      closeModal.addEventListener('click', () => {
        if (signupModal) {
          signupModal.show = false;
        }
      });
    }

    alertsSignUp.addEventListener('click', () => {
      if (signupModal && closeModal) {
        signupModal.show = true;
      }
    });
  }

  const updateQueryString = (name, detail) => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set(name, detail);

    location.search = queryParams.toString();
  };

  const clearFilters = document.querySelectorAll('.advisories__clear-filters');

  if (clearFilters) {
    const queryStrings = new URLSearchParams(location.search);

    for (let index = 0; index < clearFilters.length; ++index) {
      const clearFilter = clearFilters[index];
      clearFilter.addEventListener('click', () => {
        location.search = '';
      });

      if (queryStrings.get('locations')) {
        clearFilter.classList.add('advisories__clear-filters--visible');
      } else {
        clearFilter.classList.remove('advisories__clear-filters--visible');
      }
    }
  }

  const clearFilter = filter => {
    const queryStrings = new URLSearchParams(location.search);
    queryStrings.delete(filter);

    location.search = queryStrings.toString();
  };

  const locationsFilter = document.querySelector('.locations-filter');

  if (locationsFilter) {
    locationsFilter.addEventListener('on-change', e => {
      if (e.target.value === 'undefined' || e.target.value.length === 0) clearFilter('locations');

      updateQueryString('locations', locationsFilter.value);
    });
    locationsFilter.addEventListener('on-clear', () => {
      clearFilter('locations');
    });
    locationsFilter.addEventListener('on-select-all', () => {
      updateQueryString('locations', locationsFilter.value);
    });
  }

  const locationSelector = document.querySelector('#location-selector');
  const locationSelectorSubmit = document.querySelector('#location-selector-submit');

  if (locationSelector && locationSelectorSubmit) {
    const initLocationSearch = () => {
      locationSelector.addEventListener('on-change', e => {
        if (e.target.value !== 'undefined' && e.target.value.length !== 0) clearErrors();
      });
      locationSelector.addEventListener('on-select-all', () => {
        clearErrors();
      });
    };

    const submitLocationSelection = () => {
      if (!locationSelector.value || locationSelector.value === 'undefined' || locationSelector.value.length === 0) {
        showErrorMessage(
          locationSelector,
          locationSelector.parentElement,
          'form__required-message',
          'form__required-message--visible'
        );

        return;
      }

      const loader = document.getElementById('advisories__loader');

      try {
        if (loader) loader.show = true;

        updateQueryString('locations', locationSelector.value);
      } catch (error) {
        console.error(error);
      } finally {
        if (loader) loader.show = false;
      }
    };

    initLocationSearch();
    locationSelectorSubmit.addEventListener('click', submitLocationSelection);
  }

  const sortBy = document.getElementById('sort-by');
  sortBy?.addEventListener('on-change', ({ detail }) => {
    const { value } = detail;

    updateQueryString('sortBy', value);
  });
};

export default advisoriesView;
