/*global grecaptcha*/

import Rest from '../rest';
import RegEx from '../regex';
import Events from '../events';
import { HttpStatusCode } from '../enums';
import { parseQueryStrings } from '../utils/urlParamUtils';

const contactUsView = () => {
  if (document.getElementById('contact-us')) {
    const departmentsAndLocations = document.getElementById('departments-locations');

    if (departmentsAndLocations) {
      departmentsAndLocations.addEventListener('on-change', ({ detail }) => {
        const { _, additionalData } = detail;
        const departmentPhone = additionalData;

        if (RegEx.usPhone.test(departmentPhone)) {
          contactPhone.href = `tel:${departmentPhone}`;
          contactPhone.textContent = departmentPhone;
          contactPhone.classList.add('contact-us__contact-phone--visible');
        } else {
          clearContactPhone();
        }

          clearFieldErrors(departmentsAndLocations);
      });

      const queryStrings = parseQueryStrings(location.search);
      const locationResult = queryStrings['location'];
      const departmentResult = queryStrings['department'];

      if (locationResult) {
        const items = departmentsAndLocations.items[1]?.items;
        const providedLocation = items?.find(x => x.label.includes(locationResult));

        if (providedLocation) {
          departmentsAndLocations.defaultValue = providedLocation.value;
        }
      } else if (departmentResult) {
        const items = departmentsAndLocations.items[0]?.items;
        const providedDepartment = items?.find(x => x.label.includes(departmentResult));

        if (providedDepartment) {
          departmentsAndLocations.defaultValue = providedDepartment.value;
        }
      }
    }

    const termsCond = document.getElementById('terms-cond');
    let agreedTermsConds = false;

    if (termsCond) {
      termsCond.addEventListener('on-change', ({ detail: checked }) => {
        if (checked) {
          agreedTermsConds = true;
          clearFieldErrors(termsCond);
        } else {
          agreedTermsConds = false;
        }
      });
    }

    window.scrollTo(0, 0);

    const submit = document.querySelector('.form__submit');

    if (submit) {
        submit.addEventListener('click', () => {
        if (validateForm()) {
          challengeUser();
        }
      });
    }

    const successModal = document.getElementById('success-modal');
    const successModalClose = document.getElementById('success-modal-close');

    if (successModal && successModalClose) {
      successModalClose.addEventListener('click', () => closeSuccessModal());
      successModal.onClose = () => closeSuccessModal();
    }

    const successModalDone = document.getElementById('success-modal-done');

    if (successModal && successModalDone) {
      successModalDone.addEventListener('click', () => closeSuccessModal());
      successModal.onClose = () => closeSuccessModal();
    }

    const errorModal = document.getElementById('error-modal');
    const errorModalClose = document.getElementById('error-modal-close');

    if (errorModal && errorModalClose) {
      errorModalClose.addEventListener('click', () => closeErrorModal());
      errorModal.onClose = () => closeErrorModal();
    }

    const errorModalDone = document.getElementById('error-modal-done');

    if (errorModal && errorModalDone) {
      errorModalClose.addEventListener('click', () => closeErrorModal());
      errorModal.onClose = () => closeErrorModal();
    }

    const form = document.querySelector('.form');
    const email = document.getElementById('email');
    const lastName = document.getElementById('last-name');
    const firstName = document.getElementById('first-name');
    const company = document.getElementById('company-name');
    const tailNumber = document.getElementById('tail-number');
    const phoneNumber = document.getElementById('phone-number');
    const comments = document.getElementById('comments-questions');
    const contactPhone = document.querySelector('.contact-us__contact-phone');
    const submitLoader = document.getElementById('submit-loader');
    const requiredFields = [comments, firstName, lastName, phoneNumber];

    requiredFields.forEach(field => {
        if (field)
            field.addEventListener('input', () => {
                clearFieldErrors(field);
            });
    });

    Events.on('recaptcha-validate', token => {
      const submitForm = async () => {
        try {
          submitLoader.show = true;

          const departmentOrLocationId = departmentsAndLocations?.value;
          const formData = {
            email: email?.value,
            company: company?.value,
            comment: comments?.value,
            tailNumber: tailNumber?.value,
            agreeToTerms: agreedTermsConds,
            phoneNumber: phoneNumber?.value,
            locationId: departmentOrLocationId,
            departmentId: departmentOrLocationId,
            senderName: `${firstName?.value} ${lastName?.value}`,
            recaptchaResponse: token
          };
          const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          };

          const response = await Rest.post(`${process.env.API_CONTACT_US}/Store`, formData, config);

          if (response?.status === HttpStatusCode.created) {
            successModal.show = true;
          } else {
            errorModal.show = true;
          }
        } catch (error) {
          console.error(error);
          errorModal.show = true;
        } finally {
          submitLoader.show = false;
        }
      };
      submitForm();
    });

    const challengeUser = () => {
      grecaptcha.execute();
    };

    const clearContactPhone = () => {
      contactPhone.href = '';
      contactPhone.textContent = '';
      contactPhone.classList.remove('contact-us__contact-phone--visible');
    };

    const clearFieldErrors = field => {
        const requiredMessage = field.parentNode.querySelector('.form__required-message--visible');
        const invalidMessage = field.parentNode.querySelector('.form__invalid-message--visible');

        if (requiredMessage) requiredMessage.classList.remove('form__required-message--visible');
        if (invalidMessage) invalidMessage.classList.remove('form__invalid-message--visible');
    };

    const clearErrors = () => {
      const requiredMessages = document.querySelectorAll('.form__required-message');

      for (let index = 0; index < requiredMessages?.length; ++index) {
        const requiredMessage = requiredMessages[index];

        if (requiredMessage) {
          requiredMessage.classList.remove('form__required-message--visible');
        }
      }

      const invalidMessages = document.querySelectorAll('.form__invalid-message');

      for (let index = 0; index < invalidMessages?.length; ++index) {
        const invalidMessage = invalidMessages[index];

        if (invalidMessage) {
          invalidMessage.classList.remove('form__invalid-message--visible');
        }
      }
    };

    const closeErrorModal = () => {
      if (errorModal) {
        errorModal.show = false;
        resetForm();
      }
    };

    const closeSuccessModal = () => {
      if (successModal) {
        successModal.show = false;
        location.reload();
      }
    };

    const resetForm = () => {
      if (form) {
        form.reset();
        termsCond.reset();
        agreedTermsConds = false;
        departmentsAndLocations.reset();
        departmentsAndLocations.value = '';
        clearContactPhone();
      }
    };

    const showErrorMessage = (field, group, errorClass, errorClassVisible) => {
      const errorMessage = group?.querySelector(`.${errorClass}`);

      if (errorMessage) {
        field.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        });
        field.focus();
        errorMessage.classList.add(errorClassVisible);
      }
    };

    const validateForm = () => {
      clearErrors();

        let hasErrors = false;

        if (!agreedTermsConds) {
            hasErrors = true;
            showErrorMessage(
                termsCond,
                termsCond.parentElement,
                'form__required-message',
                'form__required-message--visible'
            );
        }

        if (email.value && !RegEx.email.test(email.value)) {
            hasErrors = true;
            showErrorMessage(email, email.parentElement, 'form__invalid-message', 'form__invalid-message--visible');
        }

        if (phoneNumber && phoneNumber.value) {
            phoneNumber.value = phoneNumber.value.trim();
        }

        if (phoneNumber && !phoneNumber.value) {
            hasErrors = true;
            showErrorMessage(
                phoneNumber,
                phoneNumber.parentElement,
                'form__required-message',
                'form__required-message--visible'
            );
        } else if (phoneNumber.value &&
                   ( ((phoneNumber.value?.match(/[^ 0-9()+-]/g)?.length ?? 0) > 0) ||
                     ((phoneNumber.value?.match(/[0-9]/g)?.length ?? 0) < 10)) ) {
            hasErrors = true;
            showErrorMessage(
                phoneNumber,
                phoneNumber.parentElement,
                'form__invalid-message',
                'form__invalid-message--visible'
            );
        }

        if (lastName && !lastName.value) {
            hasErrors = true;
            showErrorMessage(lastName, lastName.parentElement, 'form__required-message', 'form__required-message--visible');
        }

        if (firstName && !firstName.value) {
            hasErrors = true;
            showErrorMessage(
                firstName,
                firstName.parentElement,
                'form__required-message',
                'form__required-message--visible'
            );
        }

        if (comments && !comments.value) {
            hasErrors = true;
            showErrorMessage(comments, comments.parentElement, 'form__required-message', 'form__required-message--visible');
        }

        if (departmentsAndLocations && !departmentsAndLocations.value) {
            hasErrors = true;
            showErrorMessage(
                departmentsAndLocations,
                departmentsAndLocations.parentElement,
                'form__required-message',
                'form__required-message--visible'
            );
        }

      return !hasErrors;
    };
  }
};

export default contactUsView;
