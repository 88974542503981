/**
 * Debounces a function.
 * @param {Function} func The function to debounce.
 * @param {Number} wait The amount in milliseconds to wait to call the function.
 * @param {Boolean} isImmediate A value that determines whether to call the function immediately.
 * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's debounce function.
 */
export function debounce(func, wait, isImmediate) {
  let timeout = null;

  return function closure() {
    const that = this;
    const args = arguments;
    const callLater = () => {
      timeout = null;

      if (!isImmediate) {
        func.apply(that, args);
      }
    };
    const callNow = isImmediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(callLater, wait);

    if (callNow) {
      func.apply(that, args);
    }
  };
}

/**
 * Calls a function once.
 * @param {Function} func The function to call.
 * @param {Object} context The context in which to execute the function.
 * @returns Any
 * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's once function.
 */
export function once(func, context) {
  let result = null;

  return function closure() {
    if (func) {
      result = func.apply(context || this, arguments);
      func = null;
    }

    return result;
  };
}

/**
 * Polls a function.
 * @param {Function} func The function to poll.
 * @param {Number} timeout The amount in milliseconds to wait to call the function.
 * @param {Number} interval The amount in milliseconds to wait to poll.
 * @returns Promise
 * @see {@link https://davidwalsh.name/essential-javascript-functions}, which is based off of David Walsh's poll function.
 */
export function poll(func, timeout, interval) {
  const endTime = Number(new Date()) + (timeout || 2000);
  interval = interval || 100;

  const checkCondition = (resolve, reject) => {
    const result = func();

    // If the condition is met, we're done!
    if (result) {
      resolve(result);
    }
    // If the condition isn't met, but the timeout hasn't elapsed, go again.
    else if (Number(new Date()) < endTime) {
      setTimeout(checkCondition, interval, resolve, reject);
    }
    // Didn't match and too much time, reject!
    else {
      reject(new Error(`timed out for ${func}: ${arguments}`));
    }
  };

  return new Promise(checkCondition);
}
