import Splide from '@splidejs/splide';
import { EventMessages } from './constants';
import Events from './events';
import './extensions/arrayExtensions';
import './extensions/objectExtensions';
import './extensions/stringExtensions';
import { debounce } from './utils/debounceUtils';

const init = (() => {
  window.onMapsInit = () => {
    const quarterOfASecond = 250;
    const timeout = setTimeout(() => {
      clearTimeout(timeout);
      Events.emit(EventMessages.mapsInit);
    }, quarterOfASecond);
  };
  // PLACE ANY CODE HERE THAT NEEDS TO BE INITIALIZED PRIOR TO LOADING THE APP.
  window.onRecaptchaValidate = token => {
    Events.emit(EventMessages.recaptchaValidate, token);
  };
  window.addEventListener('DOMContentLoaded', () => {
    const splides = document.querySelectorAll('.splide');

    for (let index = 0; index < splides?.length; ++index) {
      const splide = splides[index];
      let slidesPerPage = 4; // Default slide count.
      if ('splidePerPage' in splide.dataset) {
        slidesPerPage = JSON.parse(splide.dataset.splidePerPage);
      }
      // Default breakpoints.
      let breakpoints = {
        768: {
          perPage: 1
        },
        992: {
          perPage: 3
        },
        1400: {
          perPage: slidesPerPage
        }
      };
      if ('splideBreaks' in splide.dataset) {
        breakpoints = JSON.parse(
          splide.dataset.splideBreaks.replace(
            /(\w+:)|(\w+ :)/g,
            matchedStr => `"${matchedStr.substring(0, matchedStr.length - 1)}":`
          )
        );
      }

      if (splide.dataset.splideOrientation === 'landscape') {
        slidesPerPage = 3;
        breakpoints = {
          992: {
            perPage: 1
          },
          1200: {
            perPage: slidesPerPage
          }
        };
      }

      const mounted = new Splide(splide, {
        perPage: slidesPerPage,
        updateOnMove: true,
        breakpoints: breakpoints
      }).mount();

      const quarterOfASecond = 250;
      const slides = splide?.querySelectorAll('.splide__slide');
      const safeWindowResize = debounce(() => {
        updateSliderUI();
      }, quarterOfASecond);
      const updateSliderUI = () => {
        if (slides?.length <= mounted._o?.perPage) {
          const prevArrow = splide?.querySelector('.splide__arrow--prev');
          const nextArrow = splide?.querySelector('.splide__arrow--next');

          prevArrow?.classList.add('splide__arrow--hidden');
          nextArrow?.classList.add('splide__arrow--hidden');

          const pagination = splide?.querySelector('.splide__pagination');
          pagination?.classList.add('splide__pagination--hidden');
        }
      };
      updateSliderUI();

      window.addEventListener('resize', () => safeWindowResize());
    }
  });
})();

export default init;
